import React, {FC} from 'react';
import Button from './Button';
import styled from '@emotion/styled';
import {BASE_COLOR, BG_COLOR} from './constants';

const Outer = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  //border: none;
  margin-top:0;
  background: ${BG_COLOR};
  width: 25px; 
  height: 25px;
  display: flex;
  align-items: center;
  justify-items: center;
`;

const ExpandIcon: FC<{isExpanded: boolean}> = (props) => {
  const {isExpanded} = props;
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" xmlSpace="preserve">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={BASE_COLOR}
        d={
          isExpanded
            ? "M15.99.99a1.003 1.003 0 00-1.71-.71l-3.29 3.29V1.99c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1H12.4l3.3-3.29c.18-.18.29-.43.29-.71zm-10 8h-4c-.55 0-1 .45-1 1s.45 1 1 1h1.59L.29 14.28a1.003 1.003 0 001.42 1.42L5 12.41V14c0 .55.45 1 1 1s1-.45 1-1v-4a1.02 1.02 0 00-1.01-1.01z"
            : "M5.99 8.99c-.28 0-.53.11-.71.29l-3.29 3.29v-1.59c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1H3.41L6.7 10.7a1.003 1.003 0 00-.71-1.71zm9-9h-4c-.55 0-1 .45-1 1s.45 1 1 1h1.59l-3.3 3.3a.99.99 0 00-.29.7 1.003 1.003 0 001.71.71l3.29-3.29V5c0 .55.45 1 1 1s1-.45 1-1V1c0-.56-.45-1.01-1-1.01z"
        }
      />
    </svg>
  );
}

const ExpandButton: FC<{isExpanded: boolean; onClick: () => void}> = (props) => {
  const {isExpanded, onClick} = props;
  return (
    <Outer>
      <StyledButton onClick={() => onClick()} title="Expand view">
        <ExpandIcon isExpanded={isExpanded}/>
      </StyledButton>
    </Outer>
  )
};

export default ExpandButton;
