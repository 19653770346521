import {hcl, rgb} from 'd3-color';
import {CompanyStepNode} from './types';

export const TRANSITION_DURATION = 0;
export const BASE_COLOR = '#355464';
export const SECONDARY_COLOR = '#8F9D35';
export const BASE_BRIGHTER_COLOR = hcl(BASE_COLOR).brighter().toString();
export const BASE_BRIGHTER_COLOR2 = hcl(BASE_COLOR).brighter(3).toString();
export const LINK_COLOR = BASE_BRIGHTER_COLOR; //'#355464'; //'#ccc';  // #F6B028
export const LINK_SEMI_DIMMED_COLOR = lighter(LINK_COLOR, 0.4).toString();
export const LINK_DIMMED_COLOR = lighter(LINK_COLOR, 0.8).toString();

export const BASE_LIGHTER_COLOR1 = lighter(BASE_COLOR, 0.5);
export const BASE_LIGHTER_COLOR2 = lighter(BASE_COLOR, 0.9);
export const BG_COLOR = '#F8F9F4';

export function lighter(color: string, alpha: number) {
  const c = rgb(color);
  c.r = c.r + (255 - c.r) * alpha;
  c.g = c.g + (255 - c.g) * alpha;
  c.b = c.b + (255 - c.b) * alpha;
  return c.toString();
}

export function opacify(color: string, v: number) {
  const c = rgb(color);
  c.opacity *= v;
  return c.toString();
}

export const ARTISANAL_MINING_STEP = 'Artisanal mining';

export const CHAIN_STEPS = [
  'Mining',
  'Artisanal processing',
  // 'Trading_1',
  'Smelting',
  'Trading',
  'Refining',
  'Precursor manufacturing',
  'Cathode manufacturing',
  'Battery cell manufacturing',
  'Battery pack manufacturing',
  'Electric car/scooter manufacturing',
];

export const COLLAPSED_CHAIN_STEPS = [
  'Mining',
  // 'Trading_1',
  'Smelting',
  'Trading',
  'Electric car/scooter manufacturing',
];

export const CHAIN_STEP_NAMES = CHAIN_STEPS.map((s) =>
  s.replaceAll(/_\d+$/g, '').replaceAll('_', ' ')
);

export const fixTradingStepKey = (key: string, otherSideKey: string) => {
  if (key === 'Trader') {
    switch (otherSideKey) {
      case 'Smelting':
        return 'Trading_1';
      case 'Refining':
        return 'Trading';
      default:
        console.warn(`Unsupported trading other side step ${otherSideKey}`);
        return undefined;
    }
  }
  return key;
};

export const getChainStepIndex = (key: string | undefined) => {
  if (key === undefined) return undefined;
  switch (key) {
    case 'Mining':
    case ARTISANAL_MINING_STEP:
      return 0;
    case 'Electric car manufacturing':
    case 'Electric scooter manufacturing':
      return CHAIN_STEPS.length - 1;
    default:
      return CHAIN_STEPS.indexOf(key);
  }
};
export const STEP_COLORS: {[key: string]: string} =
  // https://gka.github.io/palettes/#/8|s|4d6c7d,eb7067,8f9d35|ffffe0,ff005e,93003a|1|1
  {
    Mining: '#4d6c7d',
    [ARTISANAL_MINING_STEP]: '#905800',
    'Artisanal processing': '#673F00',
    Trading_1: '#ddddf2',
    Smelting: '#6a6e7a',
    Trading: '#ddddf2',
    Refining: '#807176',
    'Precursor manufacturing': '#947372',
    'Cathode manufacturing': '#a4776c',
    'Battery cell manufacturing': '#b07c64',
    'Battery pack manufacturing': '#b68559',
    'Electric car/scooter manufacturing': '#8f9d35',
  };

export const getStepColor = (
  stepName: string,
  node?: CompanyStepNode
): string => {
  if (node?.options?.originalStepName === ARTISANAL_MINING_STEP) {
    return STEP_COLORS[ARTISANAL_MINING_STEP];
  }
  return STEP_COLORS[stepName] || '#ccc';
};
