import * as React from "react"
import {FC} from 'react';

// TODO: attribution https://thenounproject.com/search/?q=zoom+in&i=225936
// Zoom In by Gregor Cresnar from the Noun Project

const MoreInfoIcon: FC<{size: number, color: string}> = ({size, color}) => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 100 125" width={size} height={size}>
      <path
        fill={color}
        d="M11.9 80c0-1.4 1.1-2.5 2.5-2.5h34.1c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5H14.4c-1.3 0-2.5-1.1-2.5-2.5zm.1 12.9h47c5.9 0 10.6-4.7 10.6-10.6v-5.7c-1.6.7-3.3 1.3-5 1.7v4c0 3.1-2.5 5.6-5.6 5.6H12c-3.1 0-5.6-2.5-5.6-5.6V30.8h8.9c5.8 0 10.6-4.8 10.6-10.6v-8.1H59c3.1 0 5.6 2.5 5.6 5.6v5.7c1.7.4 3.4 1 5 1.7v-7.4c0-5.9-4.7-10.6-10.6-10.6H24.3c-.6 0-1.2.1-1.8.3-.6.2-1.1.6-1.5 1L2.8 25.9c-.5.4-.8 1-1.1 1.6s-.4 1.2-.4 1.9v53c.1 5.7 4.8 10.5 10.7 10.5zm24.8-60.4c.5-.5 1-1.1 1.5-1.6 1.3-1.3 2.7-2.4 4.1-3.4h-9.1c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5h3.5zm-22.2 40h25.5c-.7-.5-1.3-1.1-1.9-1.7-1-1-2-2.1-2.8-3.3H14.6c-1.4 0-2.5 1.1-2.5 2.5s1.2 2.5 2.5 2.5zM12.1 60c0 1.4 1.1 2.5 2.5 2.5h17.9c-.7-1.6-1.3-3.3-1.7-5H14.6c-1.3 0-2.5 1.1-2.5 2.5zm2.5-7.5H30c0-.6-.1-1.1-.1-1.7 0-1.1.1-2.2.2-3.3H14.6c-1.4 0-2.5 1.1-2.5 2.5s1.2 2.5 2.5 2.5zM12.1 40c0 1.4 1.1 2.5 2.5 2.5h16.6c.5-1.7 1.2-3.4 2.1-5H14.6c-1.3 0-2.5 1.1-2.5 2.5zm46.1-12.3c5.9 0 11.9 2.3 16.4 6.8 8.3 8.3 9 21.3 2.1 30.4l2.5 2.5.9-.9 17.3 17.3c1.7 1.7 1.7 4.5 0 6.2-.9.9-2 1.3-3.1 1.3s-2.2-.4-3.1-1.3L73.8 72.7l.9-.9-2.5-2.5c-4.1 3.1-9.1 4.7-14 4.7-5.9 0-11.9-2.3-16.4-6.8-9.1-9.1-9.1-23.7 0-32.8 4.5-4.5 10.4-6.7 16.4-6.7zm0 6c-4.4 0-8.8 1.7-12.1 5-6.7 6.7-6.7 17.5 0 24.2 3.3 3.3 7.7 5 12.1 5s8.8-1.7 12.1-5c6.7-6.7 6.7-17.5 0-24.2-3.4-3.3-7.7-5-12.1-5zm-9.1 19.6h6.6v6.6c0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5v-6.6h6.6c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5h-6.6v-6.6c0-1.4-1.1-2.5-2.5-2.5s-2.5 1.1-2.5 2.5v6.6h-6.6c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5z" />
        // Created by Gregor Cresnar from the Noun Project
    </svg>
  )
}

export default MoreInfoIcon
