import React, {useMemo} from 'react';
import Triangle from './Triangle';
import Button from './Button';
import styled from '@emotion/styled';
import {BASE_LIGHTER_COLOR1} from './constants';

export interface Props<ItemType> {
  items: ItemType[];
  selectedItem: ItemType | undefined;
  label: string;
  onChange: (item: ItemType) => void;
}


const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &>*+* { margin-left: 5px; }
`;

const LabelBottom = styled.div`
  //font-size:
  //position: absolute;
  //left: 5px; top: 5px;
  font-size: 0.5rem;
  line-height: 2px;
  color: ${BASE_LIGHTER_COLOR1};
`;

const PagesOuter = styled.div`
  display: flex;
  line-height: 25px;
  justify-content: center;
  min-width: 60px; 
`;

const TriangleButton = styled(Button)`
  border: none;
  display: flex;
  padding: 3px 2px;
  &:hover {
    path {
      transition: fill 0.2s;
      fill: white; 
    }
  }
`;

const PagingFactory = <ItemType extends any>(): React.FC<Props<ItemType>> => (props) => {
  const {items, selectedItem, label, onChange} = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const selectedIndex = useMemo(
    () => selectedItem ? items.indexOf(selectedItem) : -1,
    [items, selectedItem]
  );
  const handleDown = () => {
    if (selectedIndex > 0) {
      onChange(items[selectedIndex - 1]);
    }
  };
  const handleUp = () => {
    if (selectedIndex >= 0 && selectedIndex < items.length - 1) {
      onChange(items[selectedIndex + 1]);
    }
  };
  return (
    <Outer>
      <Row>
        {selectedItem &&
        <TriangleButton onClick={handleDown}>
          <Triangle rotation={-90}/>
        </TriangleButton>}
        <PagesOuter>
          {selectedIndex >= 0
            // ? `${addEnding(selectedIndex + 1)} of ${items.length} ${label}`
            ? `${selectedIndex + 1} of ${items.length}`
            : `${items.length} ${label}`
          }
        </PagesOuter>
        {selectedItem &&
        <TriangleButton onClick={handleUp}>
          <Triangle rotation={90}/>
        </TriangleButton>}
      </Row>
      {selectedIndex >= 0 &&
        <LabelBottom>{label}</LabelBottom>
      }
    </Outer>
  );
};

// function addEnding(v: number) {
//   switch (Math.floor(v) % 10) {
//     case 1: return `${v}st`;
//     case 2: return `${v}nd`;
//     case 3: return `${v}rd`;
//     default: return `${v}th`;
//   }
// }


export default PagingFactory;
