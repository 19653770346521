import styled from '@emotion/styled';
import React from 'react';
import Button from './Button';
import Modal from './Modal';

export interface Props {
  onClose: () => void;
}

const CloseButton = styled(Button)`
  padding: 10px 20px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  & > section {
    font-size: 1.5rem;
  }
  a:hover {
    text-decoration: underline;
  }
  button {
    font-size: 1.1rem;
  }
`;

const AboutModal: React.FC<Props> = (props) => {
  const {onClose} = props;
  return (
    <Modal width="100%" maxWidth="80%" onClose={onClose}>
      <Body>
        <h1>About</h1>

        <p className="parag">
          Cobalt is an essential component of rechargeable batteries and its
          demand is set to increase in the next few years due to the energy
          transition. Around 70% of all cobalt comes from the Democratic
          Republic of Congo (DRC), where mining is often linked to risks of
          social, governance, and environmental harm. The cobalt supply chain
          tends to be opaque, even to the companies involved, which makes it
          more difficult to estimate the exposure to such risks.
        </p>

        <p className="parag">
          Resource Matters' cobalt supply chain platform displays information
          about current or future cobalt-related transactions across the global
          supply chain. We understand transactions as “an occasion when someone
          buys or sells something, or when money is exchanged or the activity of
          buying or selling something”. This excludes events such as
          investments, research deals, agreements to constitute joint ventures
          and so on.
        </p>

        <p className="parag">
          Our goal is to bring greater transparency to cobalt-related supply
          chains, and to help companies, governments and NGOs improve their
          assessment of risks that arise from engaging with companies with
          contested corporate behavior.
        </p>

        <p className="parag">
          The dataset contains information on the companies involved in the
          transaction (i.e. both the buyer and the supplier companies), their
          parent companies, and the commodity transacted. We illustrate the
          physical start and endpoints of the transaction, as accurately as
          possible, i.e. the facilities where the commodity is produced and
          purchased, as well as their type and their location.
        </p>

        <p className="parag">
          To test our methodology, we have focused on the largest cobalt
          producer, Glencore, and its subsidiaries Mutanda Mining and Kamoto
          Copper Company. Hence, we wish to stress that the snapshot of the
          cobalt supply chain presented here is incomplete.
        </p>

        <p className="parag">
          Resources Matters wants to update and expand this platform, include
          additional mining companies and a wider range of end-users, strengthen
          source references, add volumes of mineral trade and period covered by
          known supply agreements, link to external websites that would help
          improve due diligence practices. All of this, provided the necessary
          support. For any suggestions, comments and opportunities to
          collaborate or offers to support this project, please contact us at{' '}
          <a href="mailto:info@resourcematters.org">info@resourcematters.org</a>
          .
        </p>

        <p className="parag">
          We thank the organisations{' '}
          <a
            className="link"
            href="https://www.business-humanrights.org/en/"
            target="_blank"
            rel="noreferrer"
          >
            Business & Human Rights Resource Centre
          </a>{''}
          ,{' '}
          <a
            className="link"
            href="https://www.transportenvironment.org/"
            target="_blank"
            rel="noreferrer"
          >
            Transport & Environment
          </a>{' '}
          and{' '}
          <a
            className="link"
            href="https://www.inclusivedevelopment.net/"
            target="_blank"
            rel="noreferrer"
          >
            Inclusive Development International
          </a>{' '}
          for their contribution to this work, as well as the {' '}
          <a
            className="link"
            href="https://11thhourproject.org/"
            target="_blank"
            rel="noreferrer"
          >
            11th Hour Project
          </a>{' '}
          and the {' '}
          <a
            className="link"
            href="https://www.sida.se/en"
            target="_blank"
            rel="noreferrer"
          >
            Swedish International Development Cooperation Agency - SIDA
          </a>{' '}
          for their financial support.
        </p>

        <CloseButton onClick={onClose}>Close</CloseButton>
      </Body>
    </Modal>
  );
};

export default AboutModal;
