import {tsvParse} from 'd3';
import {feature} from 'topojson-client';
import create from 'zustand';
import {fetchJson, fetchTsv} from './fetchTsv';
import {collapseData, prepareData} from './prepareData';
import {
  Company,
  CompanyStepNode,
  Data,
  Facility,
  FetchState,
  FetchStatus,
  Link,
} from './types';

const DATA_URL = 'data/links.csv';
const BHRRC_DATA_URL = 'data/bhrrc-companies.csv';
const BHRRC_NEWS_DATA_URL = 'data/bhrrc-news.json';
const URL_PARAMS = URLSearchParams
  ? new URLSearchParams(window.location.search)
  : undefined;
const PREFILTER_COMPANIES = URL_PARAMS?.get('prefilter')?.split(',');

export function getDataIfLoaded<T, S>(
  state: FetchState<T> | undefined,
  selector: (data: T) => S
) {
  if (state !== undefined && state.status === FetchStatus.DONE) {
    return selector(state.data);
  }
  return undefined;
}

export type Connected = {
  nodes: Set<CompanyStepNode>;
};

export type Store = {
  // selected: string | undefined;
  data: FetchState<Data> | undefined;
  world: FetchState<any> | undefined;
  countryCentroids: FetchState<any> | undefined;
  fetchData: () => void;
  fetchWorld: () => void;
  fetchCountries: () => void;
  isSimplified: boolean;
  setSimplified: (v: boolean) => void;
  selectedStep: number | undefined;
  setSelectedStep: (step: number | undefined) => void;
  selectedFacility: Facility | undefined;
  setSelectedFacility: (facility: Facility | undefined) => void;
  selectedCompany: Company | undefined;
  setSelectedCompany: (company: Company | undefined) => void;
  hoverCompanyNode: CompanyStepNode | undefined;
  setHoverCompanyNode: (node: CompanyStepNode | undefined) => void;
  hoverLink: Link | undefined;
  setHoverLink: (link: Link | undefined) => void;
  // getSelectedNode: () => CompanyStepNode | undefined;
  shouldHideNode: (node: CompanyStepNode) => boolean;
  shouldHideFacility: (facility: Facility) => boolean;
  shouldHideLink: (node: Link) => boolean;
  // connectedToHovered: Connected | undefined;
};

// export function shouldHideNode(node: GraphNode, connectedToHovered: Connected) {
//   console.log(connectedToHovered);
//   if (!connectedToHovered) return false;
//   return connectedToHovered.nodes.has(node) ? false : true;
// },

function findNodes(
  graph: CompanyStepNode[][],
  predicate: (node: CompanyStepNode) => boolean
) {
  const result = new Array<CompanyStepNode>();
  for (const stepNodes of graph) {
    for (const node of stepNodes) {
      if (predicate(node)) {
        result.push(node);
      }
    }
  }
  return result;
}

const defaultShouldHide = {
  shouldHideNode: () => false,
  shouldHideLink: () => false,
  shouldHideFacility: () => false,
};

export function areRelated(c1: Company | undefined, c2: Company | undefined) {
  if (!c1?.name || !c2?.name) return false;
  return (
    c1 === c2 ||
    c1.name === c2.name ||
    (c1.parent?.name && c1.parent.name === c2.name) ||
    (c2.parent?.name && c2.parent.name === c1.name) ||
    (c1.jointVentures !== undefined &&
      c1.jointVentures.some(
        (jv) => jv.company2?.name && jv.company2.name === c2.name
      )) ||
    (c2.jointVentures !== undefined &&
      c2.jointVentures?.some(
        (jv) => jv.company2?.name && jv.company2.name === c1.name
      ))
  );
}

export function isSameOrSubsidiary(
  parent: Company | undefined,
  c: Company | undefined
) {
  if (!parent?.name || !c?.name) return false;
  return (
    parent === c ||
    parent.name === c.name ||
    (c.parent?.name != null && c.parent.name === parent.name)
    // || (parent.jointVentures !== undefined && parent.jointVentures.some(jv => (jv.company2?.name && jv.company2.name === c.name))) ||
    // (c.jointVentures !== undefined && c.jointVentures?.some(jv => (jv.company2?.name && jv.company2.name === parent.name)))
  );
}

export function isFacilityLink(link: Link, facility: Facility | undefined) {
  if (!facility) return false;
  const {id} = facility;
  return link.buyerFacility.id === id || link.supplierFacility.id === id;
}

export const useStore = create<Store>((set, get): Store => {
  // function updateCountries() {
  //   const {data, world} = get();
  //   if (data?.status === FetchStatus.DONE && world?.status === FetchStatus.DONE) {
  //     const countries = new Set<string>();
  //     for (const d of data.data.links) {
  //       countries.add(d.datum['Country of Supplier']);
  //       countries.add(d.datum['Country of Buyer']);
  //     }
  //
  //     const countriesByName = world.data.features.reduce(
  //       (m, d) => { m.set(d.properties.name, d); return m; },
  //       new Map<string, [number,number]>
  //     )
  //   }
  // }

  function updateConnected() {
    const {selectedCompany, selectedFacility, data} = get();
    if (!selectedCompany && !selectedFacility) {
      set(defaultShouldHide);
      return;
    }
    if (data?.status === FetchStatus.DONE) {
      const {connectedNodes, connectedFacilities, connectedLinkIds} =
        getConnected(
          data.data.prepared.graph,
          selectedCompany,
          selectedFacility
        );

      set({
        shouldHideNode: (node: CompanyStepNode) =>
          connectedNodes.has(node) ? false : true,
        shouldHideLink: (link: Link) =>
          connectedLinkIds.has(link.id) ? false : true,
        shouldHideFacility: (facility: Facility) =>
          connectedFacilities.has(facility) ? false : true,
      });
    }
  }
  return {
    ...defaultShouldHide,
    isSimplified: false,
    data: undefined,
    world: undefined,
    countryCentroids: undefined,
    selectedCompany: undefined,
    hoverCompanyNode: undefined,
    selectedStep: undefined,
    selectedFacility: undefined,
    hoverLink: undefined,
    // connectedToHovered: undefined,
    //
    // shouldHideNode: (node) => {
    //   const {connectedToHovered} = get();
    //   console.log(connectedToHovered);
    //   if (!connectedToHovered) return false;
    //   return connectedToHovered.nodes.has(node) ? false : true;
    // },

    setSelectedCompany: (company: Company | undefined) => {
      const {selectedCompany} = get();
      set({
        selectedCompany: company === selectedCompany ? undefined : company,
        selectedFacility: undefined,
      });
      updateConnected();
    },

    setHoverCompanyNode: (node: CompanyStepNode | undefined) => {
      const {hoverCompanyNode} = get();
      if (node !== hoverCompanyNode) {
        set({
          hoverCompanyNode: node,
        });
      }
    },

    setHoverLink: (link: Link | undefined) => {
      const {hoverLink} = get();
      if (link !== hoverLink) {
        set({
          hoverLink: link,
        });
      }
    },

    setSimplified: (v: boolean) => {
      const {isSimplified, data} = get();
      if (isSimplified !== v) {
        set({
          isSimplified: v,
          selectedCompany: undefined,
          selectedFacility: undefined,
        });
        if (data?.status === FetchStatus.DONE) {
          set({
            data: {
              status: FetchStatus.DONE,
              data: {
                ...data.data,
                prepared: v ? data.data._simplified : data.data._raw,
              },
            },
          });
        }
        updateConnected();
      }
    },

    setSelectedFacility: (facility: Facility | undefined) => {
      set({
        selectedFacility: facility,
      });
      // if (facility !== undefined) {
      //   set({
      //     selectedCompany: facility.company,
      //   });
      // }
      updateConnected();
    },

    setSelectedStep: (stepIndex: number | undefined) => {
      const {selectedStep} = get();
      if (selectedStep !== stepIndex) {
        set({
          selectedStep: stepIndex,
        });
        updateConnected();
      }
    },

    fetchCountries: async () => {
      const response = await fetch('/data/countries.csv');
      if (response.ok) {
        try {
          const data = tsvParse(await response.text()).map((row) => ({
            name: row.name,
            centroid: [+(row.lon ?? 0), +(row.lat ?? 0)],
          }));
          set({countryCentroids: {status: FetchStatus.DONE, data}});
        } catch (err) {
          set({countryCentroids: {status: FetchStatus.ERROR}});
          console.log(err);
        }
      } else {
        set({countryCentroids: {status: FetchStatus.ERROR}});
      }
    },

    fetchWorld: async () => {
      const response = await fetch('/data/world.json');
      if (response.ok) {
        try {
          const world = await response.json();
          const data = feature(world, world.objects.subunits);
          set({world: {status: FetchStatus.DONE, data}});
          // updateCountries();
        } catch (err) {
          set({world: {status: FetchStatus.ERROR}});
          console.log(err);
        }
      } else {
        set({world: {status: FetchStatus.ERROR}});
      }
    },

    fetchData: async () => {
      set({data: {status: FetchStatus.LOADING}});
      try {
        const [parsedCsvData, bhrrcCompanies, bhrrcNews] = await Promise.all([
          await fetchTsv(DATA_URL),
          await fetchTsv(BHRRC_DATA_URL),
          (await fetchJson(BHRRC_NEWS_DATA_URL, false)) ?? [],
        ]);
        let raw = prepareData(parsedCsvData, bhrrcCompanies, bhrrcNews);

        if (PREFILTER_COMPANIES && PREFILTER_COMPANIES.length > 0) {
          const filteredLinkIds = new Set<string>();
          for (const name of PREFILTER_COMPANIES) {
            const {connectedLinkIds} = getConnected(
              raw.graph,
              raw.companiesByName.get(name),
              undefined
            );
            connectedLinkIds.forEach((id) => filteredLinkIds.add(id));
          }
          const filteredLinkIDs = new Set<string>(
            raw.links
              .filter((link) => filteredLinkIds.has(link.id))
              .map((link) => link.datum['ID'])
          );
          console.log(
            '===PREFILTERED LINK IDs:\n' +
              Array.from(filteredLinkIDs.values()).sort().join('\n')
          );
          raw = prepareData(
            parsedCsvData.filter((d) => filteredLinkIDs.has(d['ID'] ?? '')),
            bhrrcCompanies,
            bhrrcNews
          );
        }

        const simplified = collapseData(raw);
        const {isSimplified} = get();
        set({
          data: {
            status: FetchStatus.DONE,
            data: {
              prepared: isSimplified ? simplified : raw,
              _simplified: simplified,
              _raw: raw,
            },
          },
        });
        // updateCountries();
      } catch (err) {
        set({data: {status: FetchStatus.ERROR}});
        console.log(err);
      }
    },

    //   getSelectedNode: () => {
    //     const {data, selectedCompany, selectedStep} = get();
    //     if (data?.status === FetchStatus.DONE) {
    //       if (selectedCompany && selectedStep) {
    //         const {graph} = data.data;
    //         return graph[selectedStep].find(node => node.company === selectedCompany);
    //       }
    //     }
    //     return undefined;
    //   }
  };
});

export function getConnected(
  graph: Array<CompanyStepNode[]>,
  selectedCompany: Company | undefined,
  selectedFacility: Facility | undefined
) {
  const connectedNodes = new Set<CompanyStepNode>();
  const connectedLinkIds = new Set<string>();
  const companyNodes = findNodes(graph, (node) => {
    let rv = true;
    if (selectedCompany) {
      rv = rv && isSameOrSubsidiary(selectedCompany, node.company);
    }
    if (selectedFacility) {
      rv = rv && isSameOrSubsidiary(selectedFacility.company, node.company);
    }
    // if (selectedFacility) {
    //   rv = rv && node.facilities.includes(selectedFacility);
    // }
    return rv;
  });
  for (const node of companyNodes) {
    const visited = new Set<CompanyStepNode>();
    const walkUp = (node: CompanyStepNode) => {
      if (visited.has(node)) return; // avoid cycles
      visited.add(node);
      connectedNodes.add(node);
      node.incomingLinks?.forEach((link) => {
        // "reverse" or "same-step" links are not followed when identifying connected nodes
        // unless one of the nodes of these links is directly selected.
        if (
          !link.isReverse ||
          isSameOrSubsidiary(selectedCompany, link.buyer.company) ||
          isSameOrSubsidiary(selectedCompany, link.supplier.company) ||
          isFacilityLink(link, selectedFacility)
        ) {
          connectedLinkIds.add(link.id);
          walkUp(link.supplier);
        }
      });
    };
    walkUp(node);
    visited.clear();
    const walkDown = (node: CompanyStepNode) => {
      if (visited.has(node)) return; // avoid cycles
      visited.add(node);
      connectedNodes.add(node);
      node.outgoingLinks?.forEach((link) => {
        // "reverse" or "same-step" links are not followed when identifying connected nodes
        // unless one of the nodes of these links is directly selected.
        if (
          !link.isReverse ||
          isSameOrSubsidiary(selectedCompany, link.buyer.company) ||
          isSameOrSubsidiary(selectedCompany, link.supplier.company) ||
          isFacilityLink(link, selectedFacility)
        ) {
          connectedLinkIds.add(link.id);
          walkDown(link.buyer);
        }
      });
    };
    walkDown(node);
  }

  const connectedFacilities = new Set<Facility>();

  // if (selectedFacility) {
  //   connectedFacilities.add(selectedFacility);
  // } else {
  for (const node of connectedNodes) {
    for (const f of node.facilities) {
      connectedFacilities.add(f);
    }
  }
  // }

  return {connectedNodes, connectedFacilities, connectedLinkIds};
}
