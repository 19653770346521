import styled from '@emotion/styled';
import React, {useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';
import {FaExternalLinkAlt} from 'react-icons/fa';
import BhrrcCompanyNews from './BhrrcCompanyNews';
import Button from './Button';
import LinkCard, {StepBadge} from './LinkCard';
import {getDataIfLoaded, useStore} from './Store';
import {BASE_LIGHTER_COLOR1, getStepColor} from './constants';
import {CompanyStepNode, Link} from './types';
import useDimensions from './useDimensions';
import useKeypress from './useKeypress';

export interface Props {
  node: CompanyStepNode;
  onClose: () => void;
}

const Title = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;

const BREAKPOINT_WIDTH = 800;
const SECTION_HEADER_HEIGHT = 35;
const MODAL_PADDING = 20;

const Outer = styled.div`
  font-size: 0.7rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.95);
  opacity: 0;
  transition: opacity 0.2s;
  &.visible {
    opacity: 1;
  }
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  z-index: 9999;

  h2 {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
  }
  h3 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
  }
`;

const Modal = styled.div`
  position: relative;
  width: 90%;
  height: 90%;
  max-width: 1000px;
  max-height: 800px;
  background: #fff;
  border: 1px solid #355464;
  border-radius: 5px;
`;

const ModalInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${BREAKPOINT_WIDTH}px) {
    flex-direction: row;
    & > * + * {
      margin-left: 20px;
    }
    overflow: hidden;
  }
  @media (max-width: ${BREAKPOINT_WIDTH}px) {
    overflow: auto;
  }
  padding: ${MODAL_PADDING}px;
`;

const Header = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  min-width: 150px;
  @media (min-width: ${BREAKPOINT_WIDTH}px) {
    max-width: 200px;
  }
  padding-bottom: 20px;
  & > * + * {
    margin-top: 1em;
  }
`;

const Body = styled.div`
  display: grid;
  width: 100%;
  @media (min-width: ${BREAKPOINT_WIDTH}px) {
    column-gap: 20px;
    grid-template-columns: repeat(2, calc(50% - 10px));
    //grid-template-columns: repeat(auto-fill, 50%);
  }
  @media (max-width: ${BREAKPOINT_WIDTH}px) {
    //row-gap: 10px;
    grid-template-rows: 1fr 1fr;
  }
`;

const Section = styled.div`
  display: grid;
  grid-template-rows: ${SECTION_HEADER_HEIGHT}px auto;
  grid-auto-columns: 100%;
  margin-bottom: 2rem;
`;

const JointVentureComposition = styled.div`
  font-size: 0.6rem;
  color: ${BASE_LIGHTER_COLOR1};
`;

const SectionHeader = styled.h3`
  //position: fixed;
`;

const List = styled.div`
  //margin: 10px 0 20px 0;
  //overflow: auto;
  & > * + * {
    margin-top: 1rem;
  }
`;

const ScrollableList = styled.div<{scrollableHeight: number}>(
  ({scrollableHeight}) => `
  @media(min-width: ${BREAKPOINT_WIDTH}px) {
    overflow: auto;
    height: ${scrollableHeight}px;
  }
  & > *+* { margin-top: 1rem; }
  padding-bottom: 20px;
`
);

const CloseButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 12pt;
  border: none;
  z-index: 2;
`;

const CompanyStepDetails: React.FC<Props> = (props) => {
  const {node} = props;
  const shouldHideLink = useStore((state) => state.shouldHideLink)!;
  const bhrrc = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.bhrrc)
  );
  const chainSteps = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.chainSteps)
  )!;
  const chainStepNames = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.chainStepNames)
  )!;
  const {stepIndex, company} = node;
  const [ref, dimensions] = useDimensions<HTMLDivElement>();
  useKeypress('Escape', props.onClose);
  const handleClose = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    props.onClose();
  };
  const [incomingLinks, outgoingLinks] = useMemo(
    () => getUniqueLinks(node, shouldHideLink),
    [node, shouldHideLink]
  );

  useEffect(() => {
    // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
    const {scrollY} = window;
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.height = '100%';
    document.body.style.top = `-${scrollY}px`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.height = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };
  });
  const {subsidiaryCompanies, jointVentures} = node;
  const bhrrcCompanyUrl = bhrrc?.companyUrls.get(company.name);
  return createPortal(
    <Outer className="visible" onClick={handleClose}>
      <Modal
        ref={ref}
        onClick={(evt: React.MouseEvent) => evt.stopPropagation()}
      >
        <CloseButton onClick={handleClose}>╳</CloseButton>
        <ModalInner>
          {dimensions && (
            <>
              <Header>
                <Title>{company.name}</Title>
                <div>
                  <StepBadge color={getStepColor(chainSteps[stepIndex], node)}>
                    {node.options?.originalStepName ||
                      chainStepNames[stepIndex]}
                  </StepBadge>
                </div>
                {company.parent && (
                  <Section>
                    <SectionHeader>Parent company</SectionHeader>
                    {company.parent.name}
                  </Section>
                )}
                {subsidiaryCompanies && (
                  <Section>
                    <SectionHeader>Subsidiaries</SectionHeader>
                    <List>
                      {subsidiaryCompanies.map((f, i) => (
                        <div key={i}>{f.name}</div>
                      ))}
                    </List>
                  </Section>
                )}
                {jointVentures && (
                  <Section>
                    <SectionHeader>Joint Ventures</SectionHeader>
                    <List>
                      {jointVentures.map((f, i) => (
                        <div>
                          <div key={i}>{f.company2.name}</div>
                          <JointVentureComposition>
                            {f.composition}
                          </JointVentureComposition>
                        </div>
                      ))}
                    </List>
                  </Section>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {bhrrcCompanyUrl ? (
                    <Button style={{maxWidth: 180}}>
                      <a
                        href={bhrrcCompanyUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src="./BHRRC-logo.svg"
                            alt="BHRRC logo"
                            style={{width: 30, height: 30}}
                          />
                          <div
                            style={{
                              flexGrow: 1,
                            }}
                          >
                            Company Profile on
                            <div style={{fontSize: 10}}>
                              Business Human Rights <br /> Resource Centre
                            </div>
                          </div>
                          <FaExternalLinkAlt
                            style={{width: 10, height: 10, alignSelf: 'start'}}
                          />
                        </div>
                      </a>
                    </Button>
                  ) : null}
                  <BhrrcCompanyNews company={company} />
                  {bhrrcCompanyUrl ? (
                    <div style={{paddingTop: 20, color: BASE_LIGHTER_COLOR1}}>
                      Disclaimer: Linked BHRRC news stories are a mix of
                      allegations of abuse as well other developments, both
                      positive and negative. The presence or absence of any type
                      of story does not imply overall company performance with
                      respect to human rights.
                    </div>
                  ) : null}
                </div>
              </Header>
              <Body>
                {incomingLinks.length > 0 && (
                  <Section>
                    <SectionHeader>Buys from</SectionHeader>
                    <ScrollableList
                      scrollableHeight={
                        dimensions.height -
                        SECTION_HEADER_HEIGHT -
                        MODAL_PADDING
                      }
                    >
                      {incomingLinks.map((link, i) => (
                        <LinkCard key={i} link={link} side={'supplier'} />
                      ))}
                    </ScrollableList>
                  </Section>
                )}
                {outgoingLinks.length > 0 && (
                  <Section>
                    <SectionHeader>Supplies to</SectionHeader>
                    <ScrollableList
                      scrollableHeight={
                        dimensions.height -
                        SECTION_HEADER_HEIGHT -
                        MODAL_PADDING
                      }
                    >
                      {outgoingLinks.map((link, i) => (
                        <LinkCard key={i} link={link} side={'buyer'} />
                      ))}
                    </ScrollableList>
                  </Section>
                )}
              </Body>
            </>
          )}
        </ModalInner>
      </Modal>
    </Outer>,
    document.body
  );
};

export default CompanyStepDetails;

function getLinkKey(link: Link, side: 'supplier' | 'buyer'): string {
  const sideCap = side === 'supplier' ? 'Supplier' : 'Buyer';
  const otherSideCap = side === 'supplier' ? 'Buyer' : 'Supplier';
  return [
    link[side].stepIndex,
    link[side].company.name,
    link.datum[`${sideCap} subsidiary company`],
    link.datum[`${otherSideCap} subsidiary company`],
  ].join(':');
}

function getUniqueLinks(
  node: CompanyStepNode,
  shouldHideLink: (node: Link) => boolean
) {
  const incomingLinks = new Map<string, Link>();
  const outgoingLinks = new Map<string, Link>();
  for (const link of node.incomingLinks.filter(
    (link) => !shouldHideLink(link)
  )) {
    incomingLinks.set(getLinkKey(link, 'supplier'), link);
  }
  // console.log(node.incomingLinks.map(link => [link, shouldHideLink(link)]));
  for (const link of node.outgoingLinks.filter(
    (link) => !shouldHideLink(link)
  )) {
    outgoingLinks.set(getLinkKey(link, 'buyer'), link);
  }
  return [
    Array.from(incomingLinks.values()),
    Array.from(outgoingLinks.values()),
  ];
}
