import {DSVRowArray, tsvParse} from 'd3';

export async function fetchTsv(url: string): Promise<DSVRowArray<string>> {
  const response = await fetch(url);
  if (response.ok) {
    try {
      return tsvParse(await response.text());
    } catch (e) {
      console.error(e);
      throw new Error(`Error parsing TSV from ${url}: ${e}`);
    }
  }
  throw new Error(`Error parsing TSV from ${url}: ${response.statusText}`);
}

export async function fetchJson(
  url: string,
  throwOnError = true
): Promise<any> {
  const response = await fetch(url);
  if (response.ok) {
    try {
      return await response.json();
    } catch (e) {
      console.error(e);
      if (throwOnError) {
        throw new Error(`Error parsing JSON from ${url}: ${e}`);
      }
    }
  }
  return null;
}
