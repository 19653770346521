import styled from '@emotion/styled';
import {ScaleBand, max, scaleBand} from 'd3';
import React, {FC, useMemo} from 'react';
import {CompanyBoxContainer, labelWidthRatio, rowHeight} from './CompanyBox';
import {getDataIfLoaded, useStore} from './Store';
import {
  ARTISANAL_MINING_STEP,
  BASE_COLOR,
  BG_COLOR,
  LINK_COLOR,
  LINK_DIMMED_COLOR,
  STEP_COLORS,
  getStepColor,
  opacify,
} from './constants';
import {CompanyStepNode, Data, Link} from './types';
import useDimensions from './useDimensions';

interface Props {
  data: Data;
}

const Outer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  background: ${BG_COLOR};
`;

const margin = {
  left: 0,
  right: 10,
  top: 0,
  bottom: 0,
};

const headerHeight = 90;
const contentPaddingTop = 30;
const contentPaddingBottom = 30;

const Container = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: column;
  //transform: translate(${margin.left}px, ${margin.top}px);
  padding-top: ${margin.top}px;
  padding-left: ${margin.left}px;
  flex-grow: 1;
  //&>*+* { margin-top: 20px; }
`;

const Header = styled.div`
  display: flex;
  position: relative;
  height: ${headerHeight}px;
`;

const HeaderStepOuter = styled.div<{
  left: number;
  width: number;
  height: number;
  color: string;
}>(
  ({left, width, height, color}) => `
  cursor: pointer;
  position: absolute;
  transform: translate(${left}px, 0);
  width: ${width}px;
  height: ${height}px;
  & > .header-title {
     width: 100px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    & > .header-item {
      gap: 5px;
      align-items: flex-end;
      flex-direction: row;
      display: flex;
      transform-origin: 0% calc(100%);
      transform:  translate(
        ${width * (1 - labelWidthRatio)}px, 
        calc(${headerHeight - 10}px - 100%)
      ) rotate(315deg);
    }
    & > .header-item:nth-child(2) {
      transform:  translate(
        ${30 + width * (1 - labelWidthRatio)}px, 
        calc(${headerHeight - 32}px - 100%)
      ) rotate(315deg);
    }
  }
  &:hover {
    background-color: ${opacify(color, 0.05)};
  }
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`
);

const HeaderStepLabel = styled.div`
  text-align: left;
  max-width: 100px;
  line-height: 1;
  display: flex;
  height: 22px;
  font-size: 0.7rem;
  align-items: center;
`;

const HeaderStepNumber = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  background: #8f9d35;
  font-size: 0.7rem;
  font-weight: bold;
  transform: rotate(-315deg);
  transform-origin: 50% 50%;
`;

const ChainsContainer = styled.div<{height: number}>(
  ({height}) => `
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  & > * {
    position: absolute;
  }  
  height: ${height}px;
  border-radius: 4px;
  border: 1px solid #eee;
  padding-top: ${contentPaddingTop - 2 /*border*/}px; 
  padding-bottom: ${contentPaddingBottom}px; 
`
);

const circleSize = 5;

const CompanyBoxesOuter = styled.div`
  pointer-events: none;
  & > * {
    pointer-events: all;
  }
`;

const HighlightedLinksSvg = styled.svg`
  pointer-events: none;
`;

const LinkPath = styled.path(
  ({dimmed}: {dimmed: boolean}) => `
  stroke-width: 1px;
  stroke: ${dimmed ? LINK_DIMMED_COLOR : LINK_COLOR};
  stroke-dasharray: 1,1;
  fill: none;
`
);

const LinkHoverPath = styled.path(
  ({highlighted}: {highlighted: boolean}) => `
  stroke-width: ${highlighted ? 2 : 10}px;
  stroke: ${highlighted ? BASE_COLOR : '#fff0'};
  fill: none;
  // cursor: pointer;
`
);

// function makeLinksIndex(dimensions: Dimensions | undefined, graph: Array<CompanyStepNode[]>) {
//   if (!dimensions) return undefined;
//   let count = 0;
//   for (const stepNodes of graph) {
//     for (const node of stepNodes) {
//       for (const link of node.outgoingLinks) {
//         count++;
//       }
//     }
//   }
//   const index = new Flatbush(count * 4);
//
//
//   for (const stepNodes of graph) {
//     for (const node of stepNodes) {
//       for (const link of node.outgoingLinks) {
//
//         // `M${x1},${y1} C${(x1 + x2) / 2},${y1} ${(x1 + x2) / 2},${y2} ${x2},${y2}`
//         // const [x,y] = p;
//         // index.add(x,y,x,y);
//       }
//     }
//   }
//
//   return index;
// }

type LinksSvgProps = {
  width: number;
  height: number;
  paddingLeft: number;
  paddingTop: number;
  // graph: Array<CompanyStepNode[]>,
  links: Array<Link>;
  shouldHideLink: (node: Link) => boolean;
  LinkPathGroup: ({
    link,
    highlighted,
  }: {
    link: Link;
    highlighted: boolean;
  }) => JSX.Element;
};

const LinksSvg: FC<LinksSvgProps> = React.memo((props) => {
  const {
    width,
    height,
    paddingLeft,
    paddingTop,
    links,
    LinkPathGroup,
    shouldHideLink,
  } = props;
  return (
    <svg width={width} height={height}>
      <g transform={`translate(${paddingLeft},${paddingTop})`}>
        {links
          .filter((link) => !shouldHideLink(link))
          .map((link) => (
            <LinkPathGroup key={link.id} link={link} highlighted={false} />
          ))}
      </g>
    </svg>
  );
});

type CompanyBoxesProps = {
  width: number;
  chartHeight: number;
  graph: Array<CompanyStepNode[]>;
  shouldHideNode: (node: CompanyStepNode) => boolean;
  x: undefined | ScaleBand<string>;
  getNodeCoords: undefined | ((node: CompanyStepNode) => [number, number]);
};
const CompanyBoxes = React.memo((props: CompanyBoxesProps) => {
  const chainSteps = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.chainSteps)
  )!;
  const {width, chartHeight, graph, shouldHideNode, x, getNodeCoords} = props;
  if (!x || !getNodeCoords) return null;
  return (
    <CompanyBoxesOuter style={{width, height: chartHeight}}>
      {graph.map((stepNodes, i) => (
        <div key={i}>
          {stepNodes.map((node, si) => {
            const color = getStepColor(chainSteps[node.stepIndex], node);
            return (
              <CompanyBoxContainer
                key={si}
                node={node}
                color={color}
                isDimmed={shouldHideNode(node)}
                bandwidth={x.bandwidth()}
                offset={getNodeCoords(node)}
              />
            );
          })}
        </div>
      ))}
    </CompanyBoxesOuter>
  );
});

const SupplyChains: FC<Props> = ({data}) => {
  const {chainSteps, chainStepNames, graph, links} = data.prepared;
  const setSelectedCompany = useStore((state) => state.setSelectedCompany);
  const selectedCompany = useStore((state) => state.selectedCompany);
  const shouldHideNode = useStore((state) => state.shouldHideNode);
  const shouldHideLink = useStore((state) => state.shouldHideLink);
  const [ref, dimensions] = useDimensions<HTMLDivElement>();
  // const [hoverLink, setHoverLink] = useState<Link>();
  const setHoverLink = useStore((state) => state.setHoverLink);
  const hoverLink = useStore((state) => state.hoverLink);
  let body = null;
  const chartHeight = useMemo(
    () =>
      Math.max(
        (dimensions?.height || 0) - headerHeight - margin.top - margin.bottom,
        (max(graph, (g) => g.length) || 0) * rowHeight
      ) + contentPaddingBottom, // - contentPaddingTop * 2,
    [graph, dimensions]
  );
  // const linksIndex = useMemo(
  //   () => makeLinksIndex(dimensions, graph),
  //   [dimensions, graph]
  // );

  const width = dimensions
    ? dimensions.width - margin.left - margin.right
    : undefined;
  const height = dimensions
    ? dimensions.height - margin.top - margin.bottom - headerHeight
    : undefined;
  const x = useMemo(() => {
    if (!width) return undefined;
    return scaleBand().domain(chainSteps).range([0, width]);
  }, [width, chainSteps]);

  const getNodeCoords = useMemo(() => {
    if (!x) return undefined;
    return (node: CompanyStepNode): [number, number] => [
      (x(chainSteps[node.stepIndex]) || 0) +
        (x.bandwidth() * (1 - labelWidthRatio)) / 2,
      node.rowIndex * rowHeight, // + (chartHeight - graph[node.stepIndex].length * rowHeight)/2
    ];
  }, [x]);

  const LinkPathGroup = useMemo(() => {
    if (!getNodeCoords || !x) return undefined;
    return ({link, highlighted}: {link: Link; highlighted: boolean}) => {
      let [x1, y1] = getNodeCoords(link.supplier);
      let [x2, y2] = getNodeCoords(link.buyer);
      if (x1 > x2) {
        [x1, y1] = getNodeCoords(link.buyer);
        [x2, y2] = getNodeCoords(link.supplier);
      }

      x1 += x.bandwidth() * labelWidthRatio;
      const path = `M${x1},${y1} C${(x1 + x2) / 2},${y1} ${
        (x1 + x2) / 2
      },${y2} ${x2},${y2}`;
      return (
        <g
          onMouseOver={() => setHoverLink(link)}
          onMouseOut={() => setHoverLink(undefined)}
        >
          <LinkPath d={path} dimmed={!selectedCompany} />
          <LinkHoverPath d={path} highlighted={highlighted} />
        </g>
      );
    };
  }, [x, getNodeCoords, selectedCompany]);

  if (dimensions && x && width && height && getNodeCoords && LinkPathGroup) {
    const paddingLeft = circleSize / 2;
    const paddingTop = 15;
    body = (
      <Container
        onMouseLeave={() => {
          setHoverLink(undefined);
        }}
      >
        <Header>
          {chainSteps.map((key, i) => (
            <HeaderStepOuter
              key={key}
              left={(x(chainSteps[i]) || 0) + 5}
              width={x.bandwidth()}
              color={getStepColor(chainSteps[i])}
              height={dimensions.height - margin.top - margin.bottom}
            >
              <div className="header-title">
                <div className="header-item">
                  <HeaderStepNumber
                    style={{background: getStepColor(chainSteps[i])}}
                  />
                  <HeaderStepLabel>{chainStepNames[i]}</HeaderStepLabel>
                </div>
                {key === 'Mining' ? (
                  <div className="header-item">
                    <HeaderStepNumber
                      style={{background: STEP_COLORS[ARTISANAL_MINING_STEP]}}
                    />
                    <HeaderStepLabel>{ARTISANAL_MINING_STEP}</HeaderStepLabel>
                  </div>
                ) : null}
              </div>
            </HeaderStepOuter>
          ))}
        </Header>
        <ChainsContainer
          height={height}
          onClick={() => setSelectedCompany(undefined)}
        >
          <LinksSvg
            width={width}
            height={chartHeight}
            paddingLeft={paddingLeft}
            paddingTop={paddingTop}
            links={links}
            shouldHideLink={shouldHideLink}
            LinkPathGroup={LinkPathGroup}
          />
          <CompanyBoxes
            {...{width, chartHeight, graph, shouldHideNode, x, getNodeCoords}}
          />
          {hoverLink && (
            <HighlightedLinksSvg width={width} height={chartHeight}>
              <g transform={`translate(${paddingLeft + 0.5},${paddingTop})`}>
                <LinkPathGroup link={hoverLink} highlighted={true} />
              </g>
            </HighlightedLinksSvg>
          )}
        </ChainsContainer>
      </Container>
    );
  }
  return <Outer ref={ref}>{body}</Outer>;
};

export default SupplyChains;
