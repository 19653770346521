import Modal from './Modal';
import React from 'react';
import styled from '@emotion/styled';
import Button from './Button';

export interface Props {
  onClose: () => void;
}


const CloseButton = styled(Button)`
  padding: 10px 20px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  & > section {
    font-size: 1rem;
  }
  a:hover { text-decoration: underline; }
  button { font-size: 1.1rem; }
`;


const ContactModal: React.FC<Props> = (props) => {
  const {onClose} = props;
  return (
    <Modal
      width="80%"
      maxWidth="510px"
      onClose={onClose}
    >
      <Body>
        <h1>Contact</h1>
        <section>
          <p>
            Your feedback, corrections, and comments are welcomed
            to <a href="mailto:info@resourcematters.org">info@resourcematters.org</a>.
          </p>
        </section>
        <br/>
        <CloseButton
          onClick={onClose}
        >Close</CloseButton>
      </Body>
    </Modal>
  );
};

export default ContactModal;
