import styled from '@emotion/styled';
import React from 'react';
import {FaExternalLinkAlt} from 'react-icons/fa';
import {
  BASE_COLOR,
  BASE_LIGHTER_COLOR1,
  BASE_LIGHTER_COLOR2,
  getStepColor,
  STEP_COLORS,
} from './constants';
import {getDataIfLoaded, useStore} from './Store';
import {Link} from './types';

export interface Props {
  link: Link;
  side: 'supplier' | 'buyer';
}

const Outer = styled.div`
  position: relative;
  padding: 10px;
  border-radius: 4px;
  & > * + * {
    margin-top: 0.5em;
  }
  transition: border 0.2s;
  cursor: pointer;
  border: 1px solid ${BASE_LIGHTER_COLOR2};
  &:hover {
    border: 1px solid ${BASE_COLOR};
  }
`;

export const Fields = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 5px;
  row-gap: 5px;
`;

export const StepBadge = styled.div(
  ({color}: {color: string}) => `
  // width: min-content;
  // margin: 10px 0px;
  padding: 3px 6px;
  border-radius: 4px;
  color: ${color === STEP_COLORS['Trading_1'] ? BASE_COLOR : '#fff'};
  background: ${color};
  display: inline-block;
`
);

export const StepBadgeArea = styled.div`
  display: flex;
  justify-content: center;
  //flex-wrap: wrap;
  & > * + * {
    margin-left: 2px;
  }
`;

export const EntryBadge = styled.div`
  display: inline-block;
  width: min-content;
  height: min-content;
  justify-self: end;
  background: #eee;
  color: ${BASE_LIGHTER_COLOR1};
  padding: 1px 4px;
  border-radius: 4px;
  // font-size: 0.6rem;
`;

const EntryText = styled.div`
  display: flex;
  align-self: flex-start;
`;
const SupplierEntryText = styled(EntryText)`
  font-weight: bold;
`;

const SourceEntryText = styled(EntryText)`
  font-size: 0.6rem;
  color: ${BASE_LIGHTER_COLOR1};
  margin-right: 5px;
`;

const LinkCard: React.FC<Props> = (props) => {
  const chainSteps = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.chainSteps)
  )!;
  const chainStepNames = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.chainStepNames)
  )!;
  const {link, side} = props;
  const sideCap = side === 'supplier' ? 'Supplier' : 'Buyer';
  // const otherSide = side === 'supplier' ? 'buyer' : 'supplier';
  const otherSideCap = side === 'supplier' ? 'Buyer' : 'Supplier';
  return (
    <Outer>
      <StepBadgeArea>
        <StepBadge color={getStepColor(chainSteps[link[side].stepIndex])}>
          {chainStepNames[link[side].stepIndex]}
        </StepBadge>
      </StepBadgeArea>
      <Fields>
        <EntryBadge>{sideCap}</EntryBadge>
        <SupplierEntryText>{link[side].company.name}</SupplierEntryText>
        <EntryBadge>Commodity</EntryBadge>
        <div>{link.inputCommodity}</div>

        {link.datum[`${sideCap} subsidiary company`] && (
          <>
            <EntryBadge>{`${sideCap} subsidiary`}</EntryBadge>
            <EntryText>{link.datum[`${sideCap} subsidiary company`]}</EntryText>
          </>
        )}
        {link.datum[`${otherSideCap} subsidiary company`] && (
          <>
            <EntryBadge>{`${otherSideCap} subsidiary`}</EntryBadge>
            <EntryText>
              {link.datum[`${otherSideCap} subsidiary company`]}
            </EntryText>
          </>
        )}
        {link.datum['Source'] && (
          <>
            <EntryBadge>Source</EntryBadge>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                alignItems: 'flex-start',
                paddingTop: 2,
              }}
            >
              {link.datum['Source']
                .split(';')
                .map((source: string, i: number) => (
                  <SourceEntryText key={i}>
                    {source.startsWith('https://') ? (
                      <a href={source} target="_blank" rel="noreferrer">
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                          }}
                        >
                          Link
                          <FaExternalLinkAlt
                            style={{width: 10, height: 10, alignSelf: 'start'}}
                          />
                        </div>
                      </a>
                    ) : (
                      source
                    )}
                  </SourceEntryText>
                ))}
              <SourceEntryText>
                <div>{link.datum['Notes1']}</div>
              </SourceEntryText>
            </div>
          </>
        )}
      </Fields>
    </Outer>
  );
};

export default LinkCard;
