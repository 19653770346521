import styled from '@emotion/styled';
import TippyHeadless from '@tippyjs/react/headless';
import React, {useState} from 'react';
import CompanyStepDetails from './CompanyStepDetails';
import MoreInfoIcon from './MoreInfoIcon';
import {isSameOrSubsidiary, useStore} from './Store';
import {BASE_COLOR, STEP_COLORS, lighter, opacify} from './constants';
import {CompanyStepNode} from './types';

export const labelWidthRatio = 0.75;
export const rowHeight = 24;
const labelHeightRatio = 0.95;

interface CompanyBoxProps {
  node: CompanyStepNode;
  color: string;
  isSelected: boolean;
  isTooltip: boolean;
  isDimmed: boolean;
  bandwidth: number;
}

const NARROW_SCREEN_BREAK_POINT = 500;

const CompanyBoxOuter = styled.div<CompanyBoxProps>(
  ({color, isSelected, isTooltip, isDimmed, bandwidth}) => {
    const borderSize = isSelected ? 5 : isTooltip ? 1 : 0;
    let width, height;
    if (isTooltip) {
      width = Math.max(bandwidth * labelWidthRatio + borderSize * 2 + 20, 120);
      height = rowHeight * 2;
    } else if (isSelected) {
      width = bandwidth * labelWidthRatio + borderSize * 2;
      height = rowHeight * labelHeightRatio + borderSize * 2;
    } else {
      width = bandwidth * labelWidthRatio;
      height = rowHeight * labelHeightRatio;
    }
    return `
  display: flex;
  color: ${color === STEP_COLORS['Trading_1'] ? BASE_COLOR : '#fff'};
  position: relative;
  ${
    isSelected || isTooltip
      ? `  
    border-radius: 10px;
    border: ${borderSize}px solid ${BASE_COLOR};
    // position: absolute;
    content: '';
  `
      : `
    border-radius: 4px;
  `
  }
  ${
    isSelected && !isTooltip
      ? `  
    top: -${borderSize}px;
  `
      : `
  `
  }
  ${
    isSelected
      ? `  
    left: -${borderSize}px;
  `
      : `
  `
  }
  ${
    isTooltip
      ? `
      width: ${width}px;
      height: auto;
      min-height: ${height}px;
      opacity: 1;
  `
      : `
      height: ${height}px;
      width: ${width}px;
      opacity: ${isDimmed ? 0.1 : 1};
  `
  }
  ${
    isSelected
      ? `  
    font-weight: bold; 
    // background: ${lighter(color, 0.5)};
  `
      : `
   // background: ${color};
  `
  }
   background: ${color};
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  font-size: 0.65rem;
  @media(max-width: ${NARROW_SCREEN_BREAK_POINT}px) {
    font-size: ${isTooltip ? '0.5' : '0.3'}rem;
  }
  cursor: pointer;
  text-align: center;
  & > .company-name {
    flex-grow: 1;
    ${
      isTooltip
        ? `
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    `
        : `
    // position: absolute;
    line-height: ${height + (isSelected && !isTooltip ? -borderSize * 2 : 0)}px;
    text-overflow: ellipsis;
    @media(max-width: ${NARROW_SCREEN_BREAK_POINT}px) {
      text-overflow: clip;
    }
    white-space: nowrap;
    `
    }
    text-shadow: 0 0 2px ${
      color === STEP_COLORS['Trading_1']
        ? opacify('#fff', 0.5)
        : opacify(BASE_COLOR, 0.5)
    };
    opacity: 0.8;
    // &:hover { opacity: 1; }
    // transition: opacity 0.2s;
    top:0; 
    left: ${8 / 2}px; 
    width: calc(100% - ${8}px);
    height: 100%;
    overflow: hidden;
  }   
  .click-to-unselect {
    position: relative;
    top: -1px;
    font-weight: normal;
    font-size: 0.5rem;
    color: ${
      color === STEP_COLORS['Trading_1']
        ? opacify(BASE_COLOR, 0.5)
        : opacify('#fff', 0.5)
    };
  }
  .more-info {
    position: absolute;
    background: ${color};
    padding: 2px;
    top: 2px;
    right: 2px;
    & > svg {
      opacity: 0.5;
      transition: opacity 0.2s;
    }
    &:hover {
      & > svg {
        opacity: 1;
      }
    }
  }
`;
  }
);
export const CompanyNodeBox = (
  props: CompanyBoxProps & {
    onMoreInfo?: (evt: React.MouseEvent) => void;
    onClick?: (evt: React.MouseEvent) => void;
  }
) => {
  const {node, isTooltip, isSelected, color} = props;
  const {onMoreInfo, onClick, ...rest} = props;
  return (
    <CompanyBoxOuter onClick={onClick} {...rest}>
      <div className="company-name">{node.company.name}</div>
      {isTooltip && (
        <div className="click-to-unselect">{`Click ${
          isSelected ? 'again' : ''
        } to ${isSelected ? 'un' : ''}select`}</div>
      )}
      {isTooltip && (
        <div className="more-info" onClick={onMoreInfo}>
          <MoreInfoIcon
            size={20}
            color={color === STEP_COLORS['Trading_1'] ? BASE_COLOR : '#fff'}
          />
        </div>
      )}
    </CompanyBoxOuter>
  );
};

export function CompanyBoxContainer({
  node,
  color,
  isDimmed,
  bandwidth,
  offset,
}: {
  node: CompanyStepNode;
  color: string;
  isDimmed: boolean;
  bandwidth: number;
  offset: [number, number];
}) {
  const [expandedNode, setExpandedNode] = useState<CompanyStepNode>();
  const setHoverCompanyNode = useStore((state) => state.setHoverCompanyNode);
  const setSelectedCompany = useStore((state) => state.setSelectedCompany);
  // const hoverCompany = useStore(state => state.hoverCompany);
  const selectedCompany = useStore((state) => state.selectedCompany);
  const selectedFacility = useStore((state) => state.selectedFacility);
  const handleClick = (evt: React.MouseEvent) => {
    if (isSameOrSubsidiary(selectedCompany, node.company)) {
      setSelectedCompany(undefined);
    } else {
      setSelectedCompany(node.company);
    }
    evt.stopPropagation();
  };
  const handleMoreInfo = (evt: React.MouseEvent) => {
    setExpandedNode(node);
    evt.stopPropagation();
  };
  const isSelected =
    isSameOrSubsidiary(selectedCompany, node.company) ||
    selectedFacility?.company === node.company;
  // && (selectedFacility.buyerSteps.has(node.stepIndex) ||
  //   selectedFacility?.supplierSteps.has(node.stepIndex))
  return (
    <div>
      <TippyHeadless
        // visible={hoverCompany && hoverCompany.name === node.company.name}
        interactive={true}
        placement="top"
        offset={({placement, reference, popper}) => {
          // return [-borderSize-2, rowHeight * (1 - labelHeightRatio / 2)+borderSize];
          return [0, -popper.height / 2 - rowHeight / 2];
        }}
        onTrigger={() => setHoverCompanyNode(node)}
        // onHide={() => setHoverCompanyNode(undefined)}
        // appendTo={document.body} // document.body prevents scrolling
        render={(attrs) => (
          <div {...attrs} onMouseOut={() => setHoverCompanyNode(undefined)}>
            <CompanyNodeBox
              node={node}
              color={color}
              isDimmed={false}
              isTooltip={true}
              isSelected={isSelected}
              onMoreInfo={handleMoreInfo}
              onClick={handleClick}
              bandwidth={bandwidth}
            />
          </div>
        )}
      >
        <div
          style={{
            position: 'absolute',
            transform: `translate(${offset[0] + 3}px,${offset[1] + 5}px)`,
          }}
          // onMouseOver={() => setHoverCompany(node.company)}
          // onMouseOut={() => setHoverCompany(undefined)}
        >
          <CompanyNodeBox
            node={node}
            color={color}
            isDimmed={isDimmed}
            isTooltip={false}
            isSelected={isSelected}
            bandwidth={bandwidth}
            onClick={handleMoreInfo}
          />
        </div>
      </TippyHeadless>

      {expandedNode && (
        <CompanyStepDetails
          node={node}
          onClose={() => {
            setExpandedNode(undefined);
          }}
        />
      )}
    </div>
  );
}
