import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {BG_COLOR} from './constants';

// From https://github.com/ajwann/svg-loaders-react

const size = 32;
const SpinningCircles = ({}: any) => (
  <svg width={size} height={size} viewBox={`0 0 58 58`}>
    <g
      transform="translate(2 1)"
      stroke="#355464"
      strokeWidth={1.5}
      fill="#355464"
      fillRule="evenodd"
    >
      <circle cx={42.601} cy={11.462} r={5}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="1;0;0;0;0;0;0;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={49.063} cy={27.063} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;1;0;0;0;0;0;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={42.601} cy={42.663} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;0;1;0;0;0;0;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={27} cy={49.125} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;0;0;1;0;0;0;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={11.399} cy={42.663} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;0;0;0;1;0;0;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={4.938} cy={27.063} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;0;0;0;0;1;0;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={11.399} cy={11.462} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;0;0;0;0;0;1;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={27} cy={5} r={5} fillOpacity={0}>
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="1.3s"
          values="0;0;0;0;0;0;0;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
    </g>
  </svg>
);


const CenterBlock = styled.div`
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
`;

const LoadingBox = styled.div`
  display: flex;
  align-self: center;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: ${BG_COLOR};
  //border: 1px solid #355464;
  //box-shadow: 0 0 2px #355464;
`;


const LoadingSpinner = () =>
  <CenterBlock><LoadingBox>
  <SpinningCircles/>
  </LoadingBox></CenterBlock>
;

export default LoadingSpinner;
