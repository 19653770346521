import React, {useEffect, useRef} from 'react';
import Flatbush from 'flatbush';
import styled from '@emotion/styled';
import {CountryCentroid} from './types';

interface Props {
  labels: CountryCentroid[];
  projection: any;
}

const LabelText = styled.text`
  transition: opacity 0.5s;
  text-anchor: middle;
  alignment-baseline: middle;
  font-size: 9px;
  paint-order: stroke;
  fill: #fff;
  stroke-width: 2px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  // stroke: #000;
  // font-weight: normal;
  // stroke: rgb(170, 20, 24);
  stroke: #ccd;
  font-weight: normal;
  opacity: 0;
`;

const pad = 1;

const LabelsOverlay: React.FC<Props> = (props) => {
  const {labels, projection} = props;
  const ref = useRef<SVGGElement>(null);
  useEffect(() => {
    if (ref.current && labels) {
      const index = new Flatbush(labels.length);
      const textNodes = ref.current.querySelectorAll('.label');
      for (let i = 0; i < textNodes.length; i++) {
        const {left, top, right, bottom} = textNodes[i].getBoundingClientRect();
        index.add(left, top, right, bottom);
      }
      index.finish();

      const toOmit = new Set<number>();
      // assuming they are sorted by size
      for (let i = textNodes.length - 1; i >= 0 ; i--) {
        if (toOmit.has(i)) continue;
        const textNode = textNodes[i];
        const {left, top, right, bottom} = textNode.getBoundingClientRect();
        const indices = index.search(left - pad, top - pad, right + pad, bottom + pad);
        for (const idx of indices) {
          if (idx !== i) {
            toOmit.add(idx);
          }
        }
      }
      for (let i = 0; i < textNodes.length; i++) {
        // @ts-ignore
        textNodes[i].style.opacity = toOmit.has(i) ? '0' : '1';
      }
    }
  }, [projection, ref.current, labels])


  // @ts-ignore
  return (
    <g ref={ref}>
      {labels && labels
      .map((d, i) => {
        const [cx, cy] = projection(d.centroid);
        return (
          <LabelText
            className="label"
            key={d.name}
            transform={`translate(${cx},${cy})`}
          >
            {d.name}
          </LabelText>
        );
      }).reverse()}
    </g>
  );
};

export default LabelsOverlay;