import throttle from 'lodash.throttle';
import {useEffect, useRef, useState} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import {RefObject} from 'react';

export interface Dimensions {
  readonly height: number;
  readonly width: number;
}

const DEFAULT_THROTTLE_DELAY = 200;

// Using a single ResizeObserver for all elements can be 10x
// more performant than using a separate ResizeObserver per element
// https://groups.google.com/a/chromium.org/forum/#!msg/blink-dev/z6ienONUb5A/F5-VcUZtBAAJ
let _observerRegistry: any;

function getObserverRegistry() {
  if (_observerRegistry === undefined) {
    const callbacks = new Map();
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
      for (const entry of entries) {
        callbacks.get(entry.target)?.(entry, observer);
      }
    });
    _observerRegistry = {
      subscribe(target: Element, callback: any) {
        resizeObserver.observe(target);
        callbacks.set(target, callback);
      },
      unsubscribe(target: Element) {
        resizeObserver.unobserve(target);
        callbacks.delete(target);
      }
    };
  }
  return _observerRegistry;
}

export function observeDimensions(
  target: Element,
  handleResize: (size: Dimensions) => void,
  throttleDelay: number = DEFAULT_THROTTLE_DELAY
) {
  const registry = getObserverRegistry();
  const handler = throttleDelay > 0 ? throttle(handleResize, throttleDelay) : handleResize;
  registry.subscribe(target, (entry: ResizeObserverEntry) => handler(getSize(target, entry)));
}

export function unobserveDimensions(target: Element) {
  const registry = getObserverRegistry();
  registry.unsubscribe(target);
}


function getSize(node: Element | null, entry: ResizeObserverEntry) {
  if (entry.contentRect) {
    const {width, height} = entry.contentRect;
    return {width, height};
  }
  if (node?.getBoundingClientRect) {
    const {width, height} = node.getBoundingClientRect();
    return {width, height};
  }
  return null;
}

/**
 * Usage example:
 * const [ref, dimensions] = useDimensions<HTMLDivElement>();
 *
 * @param throttleDelay
 * @returns {[React.RefObject<Element>, {width: number, height: number} | null]}
 */
export default function useDimensions<T extends Element>(
  throttleDelay?: number
): [RefObject<T>, Dimensions | undefined] {
  const ref = useRef(null);
  const [size, setSize] = useState(null);

  useEffect(() => {
    const {current} = ref;
    if (!current) {
      return;
    }

    let didUnobserve = false;
    observeDimensions(
      current!,
      (newSize: Dimensions) => {
        if (didUnobserve) return;
        if (newSize) {
          // @ts-ignore
          // if (!size || newSize.width !== size.width || newSize.height !== size.height)
          {
            // @ts-ignore
            setSize(newSize);
          }
        }
      },
      throttleDelay
    );
    return () => {
      didUnobserve = true;
      if (current != null) {
        unobserveDimensions(current!);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throttleDelay]);

  return [ref, size ?? undefined];
}
