import styled from '@emotion/styled';

export const GridArea = styled.div<{name:string}>(({name}) => `
  grid-area: ${name};
  position: relative;
`);

export const Cell = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  width: 100%;
  height: 100%;
`;
