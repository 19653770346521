import React from 'react';
import {Link} from 'react-router-dom';
import handsImg from './images/cobalt-hand-crop.jpg';
import styled from '@emotion/styled';
import logo from './images/logo.png';
import gvlogo from './images/gvlogo.png';
import {useFetchData} from './Explore';

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  padding-top: 40px;
  max-width: 80%;
  margin: 0;
  & > *+* { margin-left: 1rem; }
  & > .title-outer {
    display: flex;
    sup {
      color: #fff;
      font-weight: bold;
      position: relative;
    }
    @media (min-width: 600px) {
      sup {
        font-size: 1rem;
        top: 15px;
        left: 3px;
      }
    }
    @media (max-width: 600px) {
      .title {
        flex: 1 1 auto;
        font-size: 1.3rem;
      }
      sup {
        //flex: 1 0 auto;
        font-size: 0.65rem;
        top: 7px;
      }
    }
  }
 `;

const Footer = styled.footer`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GVLogo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.55rem;
  &>div {
    position: relative; top:10px; left: -5px;
  }
  //opacity: 0.4;
  //transition: opacity 0.5s;
  //&:hover { opacity: 1; }
`;

const RMLogo = styled.div`
  display: flex;
  font-size: 0.9rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 0.5rem;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Credits = styled.div`
  display: flex;
  & > *+* { margin-left: 8rem; }
`;

// const ContoursBg = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 500px;
//   top: -180px; left: 0px;
//   background-repeat: no-repeat;
//   background-position: top center;
//   background-size: ${472*2}px;
//   background-image: url('${contoursImg}');
// `;

const MainSection = styled.section`
  box-shadow: 0 0 1px #8F9D35;
  background: rgba(255,255,255,1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 30px 0px;
`;

const Main = styled.main`
  padding: 0.5rem 0.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


export default function Home() {
  useFetchData();
  return (
    <>
      <Header>
        <img src={logo} width={64} height={64}/>
        <div className="title-outer">
          <h1 className="title">COBALT SUPPLY CHAIN</h1><sup>BETA</sup>
        </div>
      </Header>
      {/*<ContoursBg/>*/}
      <Main>
        <MainSection>
          <p className="description">
            The Democratic Republic of Congo (DRC) produces around 70% of all
            cobalt consumed in the world. Demand for cobalt is set to increase
            substantially in the next years due to the energy transition
            underway, as it is used to make electric batteries more stable and
            increase their energy density.
          </p>
          <img src={handsImg} width={262} height={147} />

          <p className="description">
            We have gathered information about the transactions of several leading
            companies in the cobalt market. We want to disclose where the cobalt
            produced by certain companies ends up, to identify potential levers and
            pressure points, in hope that this helps improve corporate behavior
            on the ground.
          </p>

          <div className="grid">
            <Link to="/explore?#disclaimer">
              <div className="card">
                <h3>Start exploring &rarr;</h3>
              </div>
            </Link>
          </div>

          {/*<p className="disclaimer">*/}
          {/*  <b>Disclaimer:</b> {DISCLAIMER}*/}
          {/*</p>*/}
        </MainSection>
      </Main>
      <Footer>
        <Credits>
          <RMLogo>
            <a href="http://resourcematters.org/">
              <img src={logo} width={42} height={42}/>
              resource matters
            </a>
          </RMLogo>
          <GVLogo>
            <div>developed by</div>
            <a href="https://geovisually.com/">
              <img src={gvlogo} width={81} height={81}/>
            </a>
          </GVLogo>
        </Credits>
      </Footer>
    </>
  );
}
