import * as React from "react"
import {BASE_COLOR} from './constants';
import {FC} from 'react';

const FitIcon: FC<{size: number}> = (props) => {
  return (
    <svg
      viewBox="-1 -1 12 12" width={props.size} height={props.size}
    >
      <path
        stroke={BASE_COLOR}
        d="M0,3 L0,0 L3,0  M7,0 L10,0 L10,3  M10,7 L10,10 L7,10  M3,10 L0,10 L0,7"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  )
};

export default FitIcon;
