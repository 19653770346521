import React from 'react';
import {BASE_COLOR} from './constants';

export interface Props {
  size?: number;
  color?: string;
  rotation?: number;
}

const Triangle: React.FC<Props> = (props) => {
  const {size = 12, rotation = 0, color = BASE_COLOR} = props;
  return (
    <svg width={size} height={size} viewBox={`-10 -10 20 20`}>
      <path
        fill={color}
        stroke="none"
        transform={`rotate(${rotation} 0 0)`}
        d="M0,-7.5 10,5 -10,5  z"
      />
    </svg>
  );
};

export default Triangle;
