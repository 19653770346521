import styled from '@emotion/styled';
import React from 'react';
import {EntryBadge, Fields, StepBadge} from './LinkCard';
import {getDataIfLoaded, useStore} from './Store';
import {BASE_LIGHTER_COLOR2, getStepColor} from './constants';
import {Link} from './types';

export interface Props {
  link: Link;
}

const Outer = styled.div`
  position: absolute;
  width: min(35%, 250px);
  min-width: 160px;
  bottom: 0px;
  left: 0px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-top: 1px solid ${BASE_LIGHTER_COLOR2};
  border-right: 1px solid ${BASE_LIGHTER_COLOR2};
  border-top-right-radius: 4px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 5px;
  }
  pointer-events: none;
  hr {
    width: 100%;
    border: none;
  }
`;

const LinkDetails: React.FC<Props> = (props) => {
  const {link} = props;
  const chainStepNames = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.chainStepNames)
  )!;
  const chainSteps = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.chainSteps)
  )!;
  return (
    <Outer>
      <Fields>
        <EntryBadge>Supplier</EntryBadge>
        <div>
          <StepBadge
            color={getStepColor(
              chainSteps[link.supplier.stepIndex],
              link.supplier
            )}
          >
            {link.supplier.company.name}
          </StepBadge>
        </div>
        <EntryBadge>Facility</EntryBadge>
        <div>{link.supplierFacility.name}</div>
        <EntryBadge>Place</EntryBadge>
        <div>
          {link.supplierFacility.place ?? link.supplierFacility.country}
        </div>
        <EntryBadge>Input commodity</EntryBadge>
        <div>{link.inputCommodity}</div>

        <div />
        <hr />

        <EntryBadge>Buyer</EntryBadge>
        <div>
          <StepBadge
            color={getStepColor(chainSteps[link.buyer.stepIndex], link.buyer)}
          >
            {link.buyer.company.name}
          </StepBadge>
        </div>
        <EntryBadge>Facility</EntryBadge>
        <div>{link.buyerFacility.name}</div>
        <EntryBadge>Place</EntryBadge>
        <div>{link.buyerFacility.place ?? link.buyerFacility.country}</div>
        <EntryBadge>Output commodity</EntryBadge>
        <div>{link.outputCommodity}</div>
      </Fields>
    </Outer>
  );
};

export default LinkDetails;
