import React from 'react';
import {EntryBadge, Fields, StepBadge, StepBadgeArea} from './LinkCard';
import {Facility} from './types';
import styled from '@emotion/styled';
import {BASE_LIGHTER_COLOR1, BASE_LIGHTER_COLOR2, getStepColor} from './constants';
import {getDataIfLoaded, useStore} from './Store';
import Button from './Button';
import useKeypress from './useKeypress';
import PagingFactory from './PagingFactory';

const FacilityPaging = PagingFactory<Facility>();

export interface Props {
  facility: Facility;
  neighbors: Facility[] | undefined;
  isSelected?: boolean;
  steps: number[] | undefined;
}

const Outer = styled.div`
  position: absolute;
  width: min(35%, 250px);
  min-width: 160px;
  bottom: 0px;
  left: 0px;
  padding: 27px 10px 10px 10px;
  background: rgba(255,255,255,0.9);
  border-top: 1px solid ${BASE_LIGHTER_COLOR2};
  border-right: 1px solid ${BASE_LIGHTER_COLOR2};
  border-top-right-radius: 4px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  &>*+*{ margin-top:5px; }
  pointer-events: none;
`;

const PagingArea = styled.div`
  display: flex;
  pointer-events: all;
  justify-content: center;
  //justify-content: flex-end;
  //position: absolute;
  //left: 5px; top: 5px;
  //margin-top:0;
`;

const CloseButton = styled(Button)`
  position: absolute;
  pointer-events: all;
  right: 5px; top: 5px;
  font-size: 12px;
  border: none;
  margin-top:0;
`;

const ClickToSelect = styled.div`
  position: absolute;
  left: 0px; top: 5px;
  width: 100%;
  text-align: center;
  font-size: 0.5rem;
  color: ${BASE_LIGHTER_COLOR1};
`;



const FacilityDetails: React.FC<Props> = (props) => {
  const {facility, steps, neighbors, isSelected} = props;
  const chainStepNames = useStore(state => getDataIfLoaded(state.data, data => data.prepared.chainStepNames))!;
  const selectedFacility = useStore(state => state.selectedFacility);
  const setSelectedFacility = useStore(state => state.setSelectedFacility);
  const handleClose = () => {
    setSelectedFacility(undefined);
  };
  const handleChangeFacility = (facility: Facility) => {
    setSelectedFacility(facility);
  };
  useKeypress('Escape', handleClose, true);
  const chainSteps = useStore(state => getDataIfLoaded(state.data, data => data.prepared.chainSteps))!;
  return (
    <Outer>
      {isSelected
        ? <CloseButton onClick={handleClose}>╳</CloseButton>
        : <ClickToSelect>Click to select this facility</ClickToSelect>
      }
      <StepBadgeArea>
        {
          steps?.map(step =>
            <StepBadge
              key={step}
              color={getStepColor(chainSteps[step])}
            >
              {chainStepNames[step]}
            </StepBadge>
          )
        }
      </StepBadgeArea>
      <Fields>
        <EntryBadge>Facility</EntryBadge>
        <div>{facility.name}</div>
        <EntryBadge>Place</EntryBadge>
        <div>{facility.place ?? facility.country}</div>
        <EntryBadge>Company</EntryBadge>
        <div><b>{facility.company.name}</b></div>
        {facility.company.parent &&
        <>
          <EntryBadge>Parent company</EntryBadge>
          <div>{facility.company.parent.name}</div>
        </>}
      </Fields>
      {neighbors && neighbors.length > 1 &&
      <PagingArea>
        <FacilityPaging
          label="facilities in the area"
          items={neighbors}
          selectedItem={isSelected ? selectedFacility : undefined}
          onChange={handleChangeFacility}
        />
      </PagingArea>}
    </Outer>
  );
};

export default FacilityDetails;