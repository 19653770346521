import {DSVRowArray, ascending, descending, range, rollup} from 'd3';
import {LineString} from 'geojson';
import {
  CHAIN_STEPS,
  CHAIN_STEP_NAMES,
  COLLAPSED_CHAIN_STEPS,
  fixTradingStepKey,
  getChainStepIndex,
} from './constants';
import {
  BhrrcCompanyNewsItem,
  Company,
  CompanyStepNode,
  Datum,
  Facility,
  JointVenture,
  Link,
  PreparedData,
} from './types';

// import uniqBy from 'lodash.uniqby';

export function prepareData(
  data: Array<any>,
  bhrrcCompanyUrls: DSVRowArray<string>,
  bhrrcNews: BhrrcCompanyNewsItem[]
): PreparedData {
  const companiesByName = new Map<string, Company>();
  const getOrCreateCompany = (
    name: string,
    parent: Company | undefined,
    subsidiaryCompany: Company | undefined,
    jointVenture: JointVenture | undefined,
    row?: Datum
  ) => {
    name = fixName(name);
    if (!name.length) {
      console.warn(`Empty company name in`, row);
    }
    let company = companiesByName.get(name);
    if (!company) {
      company = {
        name,
        parent,
        subsidiaryCompanies: undefined,
        jointVentures: undefined,
      };
      companiesByName.set(name, company);
    }
    if (subsidiaryCompany) {
      if (company.subsidiaryCompanies) {
        if (!company.subsidiaryCompanies.includes(subsidiaryCompany)) {
          company.subsidiaryCompanies.push(subsidiaryCompany);
        }
      } else {
        company.subsidiaryCompanies = [subsidiaryCompany];
      }
    }
    if (jointVenture) {
      if (company.jointVentures) {
        if (!company.jointVentures.includes(jointVenture)) {
          company.jointVentures.push(jointVenture);
        }
      } else {
        company.jointVentures = [jointVenture];
      }
    }
    return company;
  };

  const secondaryCompaniesByName = new Map<string, Company>();
  const getOrCreateSecondaryCompany = (
    name: string,
    parent: Company | undefined
  ) => {
    name = name.trim();
    if (!name.length) return undefined;
    return getOrCreateCompany(name, parent, undefined, undefined);
  };

  const jointVenturesByKey = new Map<string, JointVenture>();
  const getOrCreateJointVenture = (
    company2: Company | undefined,
    composition: string | undefined
  ) => {
    if (!company2 || !composition?.length) return undefined;
    const key = company2.name;
    let jointVenture = jointVenturesByKey.get(key);
    if (!jointVenture) {
      jointVenture = {
        key,
        company2,
        composition,
      };
      jointVenturesByKey.set(key, jointVenture);
    }
    return jointVenture;
  };

  const facilitiesById = new Map<string, Facility>();
  const getOrCreateFacility = (
    name: string,
    company: Company,
    country: string,
    place: string,
    subsidiaryCompany: Company | undefined,
    lat: unknown,
    lon: unknown
  ) => {
    const betterName = fixName(name || subsidiaryCompany?.name || company.name);
    const id = `${betterName}:${Number(lat).toFixed(3)}:${Number(lon).toFixed(
      3
    )}`;
    let facility = facilitiesById.get(id);
    if (!Number.isFinite(Number(lat))) {
      console.warn(
        `Invalid lat for facility ${betterName}: "${lat}", replacing with 0`
      );
      lat = 0;
    }
    if (!Number.isFinite(Number(lon))) {
      console.warn(
        `Invalid lon for facility ${betterName}: "${lon}", replacing with 0`
      );
      lon = 0;
    }

    if (!facility) {
      facility = {
        id,
        name: betterName,
        company,
        country,
        place: place ? place.replaceAll('_', ' ').replaceAll(' -', ', ') : '',
        subsidiaryCompany,
        coords: [Number(lon), Number(lat)],
        // outgoingLinks: new Array<Link>(),
        // incomingLinks: new Array<Link>(),
        // supplierSteps: new Set<number>(),
        // buyerSteps: new Set<number>(),
      };
      facilitiesById.set(id, facility);
    }
    return facility;
  };

  const graph = range(CHAIN_STEPS.length).map(
    () => new Array<CompanyStepNode>()
  );
  const getOrCreateCompanyNode = (
    stepIndex: number,
    company: Company | undefined,
    subsidiaryCompany: Company | undefined,
    jointVenture: JointVenture | undefined,
    options?: CompanyStepNode['options']
  ): CompanyStepNode | undefined => {
    if (!company || stepIndex < 0) return undefined;
    let stepNodes = graph[stepIndex];
    let node = stepNodes.find((n) => n.company.name === company.name);
    if (!node) {
      node = {
        stepIndex,
        rowIndex: stepNodes.length,
        company,
        outgoingLinks: new Array<Link>(),
        incomingLinks: new Array<Link>(),
        facilities: new Array<Facility>(),
        subsidiaryCompanies: undefined,
        jointVentures: undefined,
        options,
      };
      stepNodes.push(node);
    }
    if (subsidiaryCompany) {
      if (node.subsidiaryCompanies) {
        if (!node.subsidiaryCompanies.includes(subsidiaryCompany)) {
          node.subsidiaryCompanies.push(subsidiaryCompany);
        }
      } else {
        node.subsidiaryCompanies = [subsidiaryCompany];
      }
    }
    if (jointVenture) {
      if (node.jointVentures) {
        if (!node.jointVentures.includes(jointVenture)) {
          node.jointVentures.push(jointVenture);
        }
      } else {
        node.jointVentures = [jointVenture];
      }
    }
    return node;
  };

  const links = new Array<Link>();
  // const linkIds = new Set<string>();

  let nextLinkId = 1;
  for (const row of data) {
    const linkId = `${nextLinkId++}`; //row['ID'];
    // if (row['Input chain step'] === 'Trading') {
    //   console.log('in',row);
    // }
    // if (row['Output chain step'] === 'Trading') {
    //   console.log('out',row);
    // }
    const inputStep = getChainStepIndex(
      fixTradingStepKey(row['Input chain step'], row['Output chain step'])
    );
    const outputStep = getChainStepIndex(
      fixTradingStepKey(row['Output chain step'], row['Input chain step'])
    );
    if (inputStep === undefined || outputStep === undefined) {
      console.warn(`Skipping row with an undefined step`, row);
      continue;
    }
    // countries.add(row['Country of Supplier']);
    // countries.add(row['Country of Buyer']);

    // if (linkIds.has(linkId)) {
    //   console.warn(`Link ${linkId} encountered more than once`);
    // } else {
    //   linkIds.add(linkId);
    // }
    const supplierSubsidiaryCompany = getOrCreateSecondaryCompany(
      row['Supplier subsidiary company'],
      undefined
    );
    const supplierJointVenture = getOrCreateJointVenture(
      getOrCreateSecondaryCompany(row['Supplier joint venture'], undefined),
      row['Supplier joint venture composition']
    );
    const supplierCompany = getOrCreateCompany(
      row['Supplier company'],
      getOrCreateSecondaryCompany(
        row['Supplier parent company(ies)'],
        undefined
      ),
      supplierSubsidiaryCompany,
      supplierJointVenture,
      row
    );
    if (supplierSubsidiaryCompany) {
      supplierSubsidiaryCompany.parent = supplierCompany;
    }
    const supplierFacility = getOrCreateFacility(
      row['Supplier facility name'],
      supplierCompany,
      row['Country of Supplier'],
      row['Place of supplier '] || row['Place of supplier'],
      supplierSubsidiaryCompany,
      row['Lat supplier'],
      row['Long supplier']
    );

    const buyerSubsidiaryCompany = getOrCreateSecondaryCompany(
      row['Buyer subsidiary company'],
      undefined
    );
    const buyerJointVenture = getOrCreateJointVenture(
      getOrCreateSecondaryCompany(row['Buyer joint venture'], undefined),
      row['Buyer joint venture composition']
    );
    const buyerCompany = getOrCreateCompany(
      row['Buyer company'],
      getOrCreateSecondaryCompany(row['Buyer parent company(ies)'], undefined),
      buyerSubsidiaryCompany,
      buyerJointVenture,
      row
    );
    if (buyerSubsidiaryCompany) {
      buyerSubsidiaryCompany.parent = buyerCompany;
    }
    const buyerFacility = getOrCreateFacility(
      row['Buyer facility name'],
      buyerCompany,
      row['Country of Buyer'],
      row['Place of buyer'],
      buyerSubsidiaryCompany,
      row['Lat buyer'],
      row['Long buyer']
    );

    const supplierNode = getOrCreateCompanyNode(
      inputStep,
      supplierCompany,
      supplierSubsidiaryCompany,
      supplierJointVenture,
      {originalStepName: row['Input chain step']}
    );
    if (supplierNode) {
      supplierNode.facilities.push(supplierFacility);
    }

    const buyerNode = getOrCreateCompanyNode(
      outputStep,
      buyerCompany,
      buyerSubsidiaryCompany,
      buyerJointVenture,
      {originalStepName: row['Output chain step']}
    );
    if (buyerNode) {
      buyerNode.facilities.push(buyerFacility);
    }

    if (supplierNode && buyerNode) {
      const link: Link = {
        id: linkId,
        isReverse: supplierNode.stepIndex >= buyerNode.stepIndex,
        supplier: supplierNode,
        buyer: buyerNode,
        buyerFacility,
        supplierFacility,
        datum: row,
        geo: {
          type: 'LineString',
          coordinates: [supplierFacility.coords, buyerFacility.coords],
        } as LineString,
        inputCommodity: getCommodity(row, 'input'),
        outputCommodity: getCommodity(row, 'output'),
        inputStep,
        outputStep,
      };
      // supplierFacility.outgoingLinks.push(link);
      // supplierFacility.supplierSteps.add(inputStep);
      // buyerFacility.buyerSteps.add(outputStep);
      // buyerFacility.incomingLinks.push(link);
      links.push(link);

      if (inputStep >= outputStep) {
        console.warn(
          `Link ${linkId} inputStep(${inputStep}) >= outputStep(${outputStep})`,
          `buyer: "${buyerCompany?.name}" supplier: "${supplierCompany?.name}"`
          // row
        );
        // continue;
      }
      supplierNode.outgoingLinks.push(link);
      buyerNode.incomingLinks.push(link);
    } else {
      console.warn(
        `Couldn't find ${supplierNode ? '' : 'supplier'} ${
          buyerNode ? '' : 'buyer'
        } for `,
        row
      );
    }
  }

  for (const stepNodes of graph) {
    stepNodes.sort((a, b) =>
      descending(
        a.incomingLinks.length + a.outgoingLinks.length,
        b.incomingLinks.length + b.outgoingLinks.length
      )
    );
    stepNodes.forEach((v, i) => (v.rowIndex = i));
  }

  const newsByCompanyId = rollup(
    bhrrcNews,
    (v) => v,
    (v) => v.company
  );
  return {
    companies: Array.from(companiesByName.values())
      .concat(Array.from(secondaryCompaniesByName.values()))
      .sort((a, b) => ascending(a.name.toLowerCase(), b.name.toLowerCase())),
    companiesByName,
    facilities: Array.from(facilitiesById.values()),
    facilitiesById,
    links,
    graph,
    chainSteps: CHAIN_STEPS,
    chainStepNames: CHAIN_STEP_NAMES,
    bhrrc: {
      companyUrls: bhrrcCompanyUrls.reduce((acc, row) => {
        const companyName = row['RM name'];
        const url = row['BHRRC link'];
        if (companyName && url) {
          acc.set(companyName, url);
        }
        return acc;
      }, new Map<string, string>()),
      newsItemsByCompany: bhrrcCompanyUrls.reduce((acc, row) => {
        const companyName = row['RM name'];
        const companyId = row['ID'];
        if (companyName && companyId) {
          const newsItems = newsByCompanyId.get(companyId);
          if (newsItems) {
            acc.set(companyName, newsItems);
          }
        }
        return acc;
      }, new Map<string, BhrrcCompanyNewsItem[]>()),
    },
  };
}

interface FacilityNodeCommodity {
  node: CompanyStepNode;
  facility: Facility;
  commodity: string;
}

export function collapseData(data: PreparedData): PreparedData {
  function makeLinkId(supplier: Facility, buyer: Facility) {
    return `${supplier.id}:->:${buyer.id}`;
  }

  function asCollapsedStep(stepIndex: number) {
    return COLLAPSED_CHAIN_STEPS.indexOf(CHAIN_STEPS[stepIndex]);
  }

  function walk(
    link: Link,
    dir: 'left' | 'right',
    sameFacility: boolean,
    list = new Array<FacilityNodeCommodity>(),
    visited = new Set<CompanyStepNode>()
  ) {
    let node, facility, links, commodity;
    switch (dir) {
      case 'left':
        node = link.supplier;
        facility = link.supplierFacility;
        commodity = link.inputCommodity;
        links = link.supplier.incomingLinks;
        if (sameFacility) {
          links = links.filter(
            (l) => l.buyerFacility === link.supplierFacility
          );
        }
        break;
      case 'right':
        node = link.buyer;
        facility = link.buyerFacility;
        commodity = link.outputCommodity;
        links = link.buyer.outgoingLinks;
        if (sameFacility) {
          links = links.filter(
            (l) => l.supplierFacility === link.buyerFacility
          );
        }
        break;
    }

    if (COLLAPSED_CHAIN_STEPS.includes(CHAIN_STEPS[node.stepIndex])) {
      list.push({node, facility, commodity});
    } else {
      if (!visited.has(node)) {
        visited.add(node);
        for (const nextLink of links) {
          walk(nextLink, dir, sameFacility, list, visited);
        }
      }
    }

    return list;
  }

  function getOrCreateNewCompanyStepNode(
    oldNode: CompanyStepNode,
    newStepIndex: number
  ) {
    const stepNodes = newGraph[newStepIndex];
    let node = stepNodes.find((n) => n.company.name === oldNode.company.name);
    if (!node) {
      node = {
        ...oldNode,
        rowIndex: stepNodes.length,
        stepIndex: newStepIndex,
        incomingLinks: new Array<Link>(),
        outgoingLinks: new Array<Link>(),
      };
      stepNodes.push(node);
    }
    return node;
  }

  const newGraph = range(COLLAPSED_CHAIN_STEPS.length).map(
    () => new Array<CompanyStepNode>()
  );

  for (
    let newStepIndex = 0;
    newStepIndex < COLLAPSED_CHAIN_STEPS.length;
    newStepIndex++
  ) {
    const stepNodes = Array<CompanyStepNode>();
    newGraph[newStepIndex] = stepNodes;
  }

  const newLinks = new Array<Link>();
  const newLinkIds = new Set<string>();

  for (const link of data.links) {
    const buyers = walk(link, 'right', true);
    const suppliers = walk(link, 'left', true);
    for (const supplier of suppliers) {
      for (const buyer of buyers) {
        const id = makeLinkId(supplier.facility, buyer.facility);
        if (!newLinkIds.has(id)) {
          const newInputStep = asCollapsedStep(supplier.node.stepIndex);
          const newOutputStep = asCollapsedStep(buyer.node.stepIndex);
          const newSupplierNode = getOrCreateNewCompanyStepNode(
            supplier.node,
            newInputStep
          );
          const newBuyerNode = getOrCreateNewCompanyStepNode(
            buyer.node,
            newOutputStep
          );
          const newLink = {
            ...link,
            id,
            supplier: newSupplierNode,
            buyer: newBuyerNode,
            supplierFacility: supplier.facility,
            buyerFacility: buyer.facility,
            inputStep: newInputStep,
            outputStep: newOutputStep,
            inputCommodity: supplier.commodity,
            outputCommodity: buyer.commodity,
            geo: {
              type: 'LineString',
              coordinates: [supplier.facility.coords, buyer.facility.coords],
            } as LineString,
          };
          newSupplierNode.outgoingLinks.push(newLink);
          newBuyerNode.incomingLinks.push(newLink);
          newLinks.push(newLink);
          newLinkIds.add(id);
        }
      }
    }
  }

  // const uniqLinks = uniqBy(newLinks, 'id');
  const uniqLinks = newLinks;
  const facilitiesById = new Map<string, Facility>();
  for (const link of uniqLinks) {
    facilitiesById.set(link.supplierFacility.id, link.supplierFacility);
    facilitiesById.set(link.buyerFacility.id, link.buyerFacility);
  }
  console.log(
    `${uniqLinks.length} unique collapsed links out of ${newLinks.length} (originally ${data.links.length})`
  );

  // Only keep nodes which have at least one link
  // for (let i = 0; i < newGraph.length; i++) {
  //   newGraph[i] = newGraph[i].filter(node => node.incomingLinks.length || node.outgoingLinks.length);
  // }

  // console.log(newGraph);
  // for (const stepNodes of newGraph) {
  //   stepNodes.sort((a, b) =>
  //     descending(
  //       a.incomingLinks.length + a.outgoingLinks.length,
  //       b.incomingLinks.length + b.outgoingLinks.length,
  //     ) ||
  //     ascending(
  //       a.company.name,  b.company.name
  //     )
  //   );
  //   stepNodes.forEach((v, i) => v.rowIndex = i);
  // }

  return {
    companies: data.companies,
    companiesByName: data.companiesByName,
    graph: newGraph,
    links: uniqLinks,
    facilities: Array.from(facilitiesById.values()),
    facilitiesById,
    chainSteps: COLLAPSED_CHAIN_STEPS,
    chainStepNames: COLLAPSED_CHAIN_STEPS.map(
      (step) => CHAIN_STEP_NAMES[CHAIN_STEPS.indexOf(step)]
    ),
    bhrrc: data.bhrrc,
  };
}

function getCommodity(row: Datum, side: 'input' | 'output') {
  const a = row[
    `${side === 'input' ? 'Input' : 'Output'} commodity`
  ]?.replaceAll('_', ' ');
  const b = row[`Type of ${side === 'input' ? 'input' : 'output'} commodity`];
  if (a) {
    return `${a}${b !== a ? ` (${b})` : ''}`;
  }
  return b;
}

function fixName(name: string) {
  return name.replaceAll('Dongfeng', 'Dongfang').trim();
}
