import styled from '@emotion/styled';
import React from 'react';
import {FaExternalLinkAlt} from 'react-icons/fa';
import Button from './Button';
import Modal from './Modal';

export interface Props {
  onClose: () => void;
}

const StartButton = styled(Button)`
  padding: 10px 20px;
`;

const DisclaimerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 20px;
  & > section {
    font-size: 1rem;
    overflow: auto;
    max-height: 300px;
  }
  @media (max-height: 600px) {
    & > section {
      max-height: 250px;
    }
  }
  a {
    text-decoration: underline;
  }
  button {
    font-size: 1.1rem;
  }
`;

const DisclaimerModal: React.FC<Props> = (props) => {
  const {onClose} = props;
  return (
    <Modal width="80%" maxWidth="610px" maxHeight="80%" onClose={onClose}>
      <DisclaimerBody>
        <h1>Disclaimer</h1>
        <section>
          <p>
            {/*This is a beta version of the platform. */}
            Resource Matters' Cobalt Supply Chain platform displays clients and
            suppliers of more than 200 companies. Information sources for each
            transaction are available by clicking on the lens icon when you
            hover over a company box (
            <a
              href="https://drive.google.com/file/d/1RLkxQ5z-FJndw41uKB-Qfj06WnaG4sf8/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here for a user guide
              <FaExternalLinkAlt
                style={{width: 10, height: 10, marginLeft: 5}}
              />
            </a>
            ).
          </p>
          <p>
            This is a work in progress. We continuously review, update and
            expand this platform so the information is subject to change. Sound
            sources will always support such changes. Note that the snapshot of
            the cobalt supply chain presented here is not complete. For
            instance, end uses other than electric scooters and cars are not
            covered. However, the mining production of the companies covered
            here totals a significant amount of the global cobalt production.
          </p>
          <p>
            Our current work includes adding a wider range of mining companies
            (including artisanal actors) and end-users, strengthening source
            references, and linking to external related websites.
          </p>
          <p>
            Feedback, corrections, and comments are welcome at{' '}
            <a href="mailto:info@resourcematters.org">
              info@resourcematters.org
            </a>
          </p>
        </section>

        <div style={{paddingTop: 20}}>
          <StartButton onClick={onClose}>Let's explore the data</StartButton>
        </div>
      </DisclaimerBody>
    </Modal>
  );
};

export default DisclaimerModal;
