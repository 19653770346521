import {LineString} from 'geojson';

// export enum ChainSteps {
//   Mining = 1,
//   Smelting = 2,
//   Refining = 3,
//   Precursor_manufacturing = 4,
//   Cathode_manufacturing = 5,
//   Battery_cell_manufacturing = 6,
//   Battery_pack_manufacturing = 7,
//   Electric_car_manufacturing = 8,
//   Electric_scooter_manufacturing = 9,
// }

// export const normalizeChainStepKey = (key: string) => {
//   switch (key) {
//     case 'Electric_car_manufacturing':
//     case 'Electric_scooter_manufacturing':
//       return CHAIN_STEPS[CHAIN_STEPS.length - 1];
//   }
//   return key;
// };
//

export type Datum = any;

export interface Company {
  name: string;
  parent: Company | undefined;
  subsidiaryCompanies: Array<Company> | undefined;
  jointVentures?: Array<JointVenture> | undefined;
}

export interface JointVenture {
  key: string;
  company2: Company;
  composition: string;
}

export interface Link {
  id: string;
  isReverse: boolean;
  geo: LineString;
  supplier: CompanyStepNode;
  buyer: CompanyStepNode;
  supplierFacility: Facility;
  buyerFacility: Facility;
  inputCommodity: string;
  outputCommodity: string;
  datum: Datum;
  inputStep: number;
  outputStep: number;
}

export interface Facility {
  id: string;
  name: string;
  country: string;
  place: string;
  company: Company;
  subsidiaryCompany: Company | undefined;
  coords: [number, number];
  // incomingLinks: Link[];
  // outgoingLinks: Link[];
  // supplierSteps: Set<number>;
  // buyerSteps: Set<number>;
}

export interface CompanyStepNode {
  stepIndex: number;
  rowIndex: number;
  company: Company;
  outgoingLinks: Array<Link>;
  incomingLinks: Array<Link>;
  facilities: Array<Facility>;
  subsidiaryCompanies: Array<Company> | undefined;
  jointVentures?: Array<JointVenture> | undefined;
  options?: {originalStepName?: string};
}

export type BhrrcCompanyNewsItem = {
  company: string;
  date: string;
  title: string;
  url: string;
};

export type BhrrcData = {
  companyUrls: Map<string, string>;
  newsItemsByCompany: Map<string, BhrrcCompanyNewsItem[]>;
};

export interface PreparedData {
  chainSteps: string[];
  chainStepNames: string[];
  companies: Company[];
  facilities: Facility[];
  facilitiesById: Map<string, Facility>;
  companiesByName: Map<string, Company>;
  // subsidiaryCompaniesByName: Map<string, Company>,
  // chains: ChainDatum[],
  graph: Array<CompanyStepNode[]>;
  links: Array<Link>;
  bhrrc: BhrrcData;
}

export interface Data {
  _raw: PreparedData;
  _simplified: PreparedData;
  prepared: PreparedData;
}

export enum FetchStatus {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  DONE = 'DONE',
}

export type FetchState<T> =
  | {status: FetchStatus.LOADING}
  | {status: FetchStatus.ERROR}
  | {status: FetchStatus.DONE; data: T};

export interface CountryCentroid {
  name: string;
  centroid: [number, number];
}
