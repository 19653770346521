import React from 'react';
import ReactSwitch from 'react-switch';
import {BASE_BRIGHTER_COLOR2, BASE_COLOR, BASE_LIGHTER_COLOR1, SECONDARY_COLOR} from './constants';
import styled from '@emotion/styled';

const WIDTH = 80;
const HANDLE_SIZE = 20;

export interface Props {
  title: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

const Outer = styled.div`
  display: flex;
  flex-direction: row;
  &>*+* { margin-left: 5px; }
  align-items: center;
  & > label {
    font-size: 0.8rem;
    cursor: pointer;
  }
  .react-switch-bg > div { 
    width: ${WIDTH - HANDLE_SIZE}px !important; 
    text-align: center; 
  }
`;

const Label = styled.div`
  font-size: 0.7rem;
  width: 100%;
  //display: flex;
  //align-content: center;
  line-height: ${HANDLE_SIZE}px;
  padding: 0 5px;
  color: #fff;
  //justify-content: center;
`;

const SwitchControl: React.FC<Props> = (props) => {
  const {title, checked, onChange} = props;
  return (
    <Outer>
      <ReactSwitch
        id={title}
        checked={checked}
        onChange={onChange}
        onColor={SECONDARY_COLOR}
        // onHandleColor={BASE_COLOR}
        uncheckedIcon={<Label>Detailed</Label>}
        checkedIcon={<Label>Simple</Label>}
        boxShadow={`0px 1px 5px ${BASE_LIGHTER_COLOR1}`}
        activeBoxShadow={`0px 1px 5px ${BASE_BRIGHTER_COLOR2}`}
        height={HANDLE_SIZE}
        handleDiameter={HANDLE_SIZE}
        width={WIDTH}
        className="react-switch"
      />
      {/*<label htmlFor={title}>{title}</label>*/}
    </Outer>
  );
};

export default SwitchControl;
