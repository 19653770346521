import styled from '@emotion/styled';
import {arc, pie, select, zoom, zoomIdentity} from 'd3';
import {GeoPath, GeoProjection, geoGraticule10, geoPath} from 'd3-geo';
import {geoWinkel3} from 'd3-geo-projection';
import Flatbush from 'flatbush';
import uniqBy from 'lodash.uniqby';
import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import Button from './Button';
import FacilityDetails from './FacilityDetails';
import FitIcon from './FitIcon';
import LabelsOverlay from './LabelsOverlay';
import LinkDetails from './LinkDetails';
import {getDataIfLoaded, isSameOrSubsidiary, useStore} from './Store';
import {
  BASE_COLOR,
  BG_COLOR,
  LINK_COLOR,
  LINK_SEMI_DIMMED_COLOR,
  getStepColor,
} from './constants';
import {Facility, Link} from './types';
import useDimensions, {Dimensions} from './useDimensions';

const GRATICULE_STROKE = '#E7EFEF';
const COUNTRY_FILL =
  // '#d7d7e7';
  // BASE_LIGHTER_COLOR2;
  '#D4DEE2';
const COUNTRY_STROKE = '#fff';
const NEIGHBOURS_MAX_DISTANCE = 20;

interface Prop {}

const Outer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #eee;
  background: ${BG_COLOR};
`;

const ControlsArea = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  button {
    width: 26px;
    height: 26px;
    margin: 0;
    //padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    //line-height: 25px;
    border-radius: 0;
    &:first-of-type {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }
    &:last-of-type {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:not(:last-of-type) {
      border-bottom: none;
      padding-bottom: 5px;
    }
  }
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 0px;
  }
`;

const DivOverlay = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Svg = styled.svg`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  //background: #D4DEE2;
  //background: #E7EFEF;
  //background: #E7EFEF;
  border-radius: 10px;
  //pointer-events: none;
`;

// const LinksAndCirclesSvg = styled(Svg)`
//.links {pointer-events: all;}
// `;

const LinkPath = styled.path`
  stroke-width: 1px;
  stroke: ${LINK_SEMI_DIMMED_COLOR};
  stroke-dasharray: 1, 1;
  fill: none;
`;

const LinkTargetPath = styled.path`
  stroke-width: 10px;
  stroke: #fff0;
  fill: none;
`;

const HoverLinkPath = styled(LinkPath)`
  stroke-width: 3px;
  stroke: ${LINK_COLOR};
  stroke-dasharray: none;
  pointer-events: none;
`;

// https://observablehq.com/@fil/map-pan-zoom
// https://observablehq.com/@d3/versor-zooming

function makeProjection() {
  return geoWinkel3();
  // geoAzimuthalEqualArea()
  //   .clipAngle(180 - 1e-3)
  //   // .rotate([-25, 20])
  //   .rotate([-15, 15])
  //   .precision(0.1)
}

interface Transform {
  k: number;
  x: number;
  y: number;
}

type Extent = [[number, number], [number, number]];

// function createVirtualReference(container: HTMLElement, x: number, y: number, size = 0) {
//   const bounds =
//     container && container.getBoundingClientRect ? container.getBoundingClientRect() : undefined;
//   const left = (bounds?.left || 0) + x - size / 2;
//   const top = (bounds?.top || 0) + y - size / 2;
//   return {
//     left,
//     top,
//     right: left + size,
//     bottom: top + size,
//     width: size,
//     height: size
//   };
// }

// function getOffsetForPlacement({placement, reference, popper}, gap = 20) {
//   switch (placement) {
//     case 'top-start':
//     case 'bottom-start':
//       return [gap, gap];
//     case 'top-end':
//     case 'bottom-end':
//       return [-gap, gap];
//     default:
//       return [0, 0];
//   }
// }
//
// function getPopperOptions(container) {
//   return {
//     modifiers: [
//       {
//         name: 'preventOverflow',
//         options: {
//           boundary: container
//         }
//       }
//     ]
//   };
// }

export interface FacilityCircleProps {
  facility: Facility;
  projection: GeoProjection;
  steps: number[] | undefined;
  circleSize: number;
  isSelected?: boolean;
  isHovered?: boolean;
}

const FacilityCircle: React.FC<FacilityCircleProps> = (props) => {
  const chainSteps = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.chainSteps)
  )!;
  const {facility, projection, circleSize, steps, isSelected, isHovered} =
    props;
  const {id, coords} = facility;
  const c = projection(coords);
  if (!c) return null;
  // const step = steps && steps.length > 0 ? steps[0] : undefined;
  // const color = step != null ? getStepColor(step) : '#ccc';
  const arcPath = arc()
    .innerRadius(0)
    .outerRadius(circleSize / 2);
  const arcs = steps ? pie().value((d) => 1)(steps) : undefined;
  const renderInner = () =>
    arcs ? (
      arcs.map((d, i) => (
        <path
          key={i}
          // @ts-ignore
          d={arcPath(d)}
          fill={getStepColor(chainSteps[d.data as number])}
        />
      ))
    ) : (
      <circle r={circleSize / 2} stroke="none" fill="#ccc" />
    );

  return (
    <g
      key={id}
      transform={`translate(${c[0]},${c[1]})`}
      // style={{
      // transition: `opacity ${TRANSITION_DURATION}ms`,
      // opacity: shouldHideFacility(node) ? 0 : 1,
      // }}
    >
      {isSelected || isHovered ? (
        <>
          <circle
            r={circleSize / 2}
            fill="#fff"
            stroke={BASE_COLOR}
            strokeWidth={isSelected ? 5 : 1}
          />
          {renderInner()}

          {/*<circle*/}
          {/*  r={circleSize / 2 - circleSize/4/2}*/}
          {/*  fill="none"*/}
          {/*  stroke="#fff"*/}
          {/*  strokeWidth={0.5}*/}
          {/*/>*/}
        </>
      ) : (
        <>
          {renderInner()}
          <circle r={(circleSize / 2) * 0.85} stroke="#fff" fill="none" />
          {/*<circle r={circleSize / 2 * 0.7} fill={color}/>*/}
        </>
      )}
    </g>
  );
};

const Links = React.memo(
  (props: {
    filteredLinks: Link[];
    path: GeoPath;
    setHoverLink: (link: Link | undefined) => void;
  }) => {
    const {path, filteredLinks, setHoverLink} = props;
    return (
      <g>
        {filteredLinks.map((link, li) => {
          const d = path(link.geo)!;
          return (
            <g key={link.id}>
              <LinkPath strokeDashoffset={li} d={d} />
              <LinkTargetPath
                onMouseOut={() => setHoverLink(undefined)}
                // onMouseOver={() => setTimeout(() => setHoverLink(link), 150)}
                onMouseOver={() => setHoverLink(link)}
                d={d}
              />
            </g>
          );
        })}
      </g>
    );
  }
);

const Circles = React.memo(
  (props: {
    filteredFacilities: Facility[] | undefined;
    stepsByFacility: Map<string, number[]>;
    projection: GeoProjection;
  }) => {
    const {filteredFacilities} = props;
    if (!filteredFacilities) return null;
    return (
      <>
        {filteredFacilities?.map((f) => (
          <FacilityCircle
            isSelected={false}
            key={f.id}
            steps={props.stepsByFacility.get(f.id)}
            facility={f}
            projection={props.projection}
            circleSize={10}
          />
        ))}
      </>
    );
  }
);

function fitProjection(dimensions: Dimensions, links: Link[]) {
  const {width, height} = dimensions;
  const padding = height * 0.1;
  const projection = makeProjection();
  const viewport: Extent = [
    [padding, padding],
    [width - padding, height - padding],
  ];
  projection.fitExtent(viewport, {
    type: 'GeometryCollection',
    geometries: links.map((link) => link.geo),
  });
  if (!projection.scale()) {
    projection.fitExtent(viewport, {
      type: 'GeometryCollection',
      geometries: [
        [-170, -58],
        [-165, -58],
        [-165, 82],
        [-170, 82],
      ].map((l) => ({
        type: 'Point' as 'Point',
        coordinates: l,
      })),
    });
  }
  return projection;
}

const FacilityMap: FC<Prop> = () => {
  const [ref, dimensions] = useDimensions<HTMLDivElement>();
  const world = useStore((state) =>
    getDataIfLoaded(state.world, (data) => data)
  );
  const countryCentroids = useStore((state) =>
    getDataIfLoaded(state.countryCentroids, (data) => data)
  );
  const facilities = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.facilities)
  );
  const links = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.links)
  );
  const hoverCompanyNode = useStore((state) => state.hoverCompanyNode);
  const selectedCompany = useStore((state) => state.selectedCompany);
  const shouldHideFacility = useStore((state) => state.shouldHideFacility);
  const shouldHideLink = useStore((state) => state.shouldHideLink);
  const selectedFacility = useStore((state) => state.selectedFacility);
  const setSelectedFacility = useStore((state) => state.setSelectedFacility);
  const zoomRef = useRef<SVGSVGElement>(null);
  // const [tooltip, setTooltip] = useState(undefined);
  const [hoverItem, setHoverItem] = useState<Facility>();
  const [neighbors, setNeighbors] = useState<Facility[]>();
  const [selectedNeighbors, setSelectedNeighbors] = useState<Facility[]>();
  const [focusPos, setFocusPos] = useState<[number, number]>();
  const hoverLink = useStore((state) => state.hoverLink);
  const setHoverLink = useStore((state) => state.setHoverLink);

  // const [isZooming, setZooming] = useState(false);
  const [transform, setTransform] = useState<Transform>();
  const filteredLinks = useMemo(() => {
    if (!links) return undefined;
    return uniqBy(
      links.filter((link) => !shouldHideLink(link)),
      (link) => `${link.supplierFacility.id}:->:${link.buyerFacility.id}`
    );
  }, [links, shouldHideLink]);

  const stepsByFacility = useMemo(() => {
    if (!filteredLinks) return undefined;
    const map = new Map<string, Array<number>>();
    const add = (f: Facility, step: number) => {
      let fsteps = map.get(f.id);
      if (!fsteps) {
        fsteps = new Array<number>();
        map.set(f.id, fsteps);
      }
      if (!fsteps.includes(step)) fsteps.push(step);
    };
    for (const link of filteredLinks) {
      add(link.supplierFacility, link.inputStep);
      add(link.buyerFacility, link.outputStep);
    }
    return map;
  }, [filteredLinks]);

  const filteredFacilities = useMemo(
    () => facilities?.filter((f) => !shouldHideFacility(f)),
    [facilities, shouldHideFacility]
  );
  const selectedCompanyFacilities = useMemo(
    () =>
      selectedCompany
        ? filteredFacilities?.filter((f) =>
            isSameOrSubsidiary(selectedCompany, f.company)
          )
        : undefined,
    [filteredFacilities, selectedCompany]
  );
  const hoverCompanyNodeFacilities = useMemo(
    () =>
      hoverCompanyNode
        ? filteredFacilities?.filter(
            (f) =>
              isSameOrSubsidiary(hoverCompanyNode.company, f.company) &&
              stepsByFacility?.get(f.id)?.includes(hoverCompanyNode.stepIndex)
          )
        : undefined,
    [filteredFacilities, stepsByFacility, hoverCompanyNode]
  );
  const selectedCompanyFacilitiesSet = useMemo(
    () =>
      selectedCompanyFacilities
        ? new Set<Facility>(selectedCompanyFacilities)
        : undefined,
    [selectedCompanyFacilities]
  );
  const projection = useMemo(() => {
    const prj = makeProjection();
    if (transform) {
      prj.scale(transform.k).translate([transform.x, transform.y]);
    }
    return prj;
  }, [transform]);

  const spatialIndex = useMemo(() => {
    if (filteredFacilities && filteredFacilities.length > 0) {
      const index = new Flatbush(filteredFacilities.length);
      for (const d of filteredFacilities) {
        const p = projection(d.coords);
        if (p) {
          const [x, y] = p;
          index.add(x, y, x, y);
        }
      }
      index.finish();
      return index;
    }
    return undefined;
  }, [filteredFacilities, projection]);

  function updateZoomToFit() {
    if (dimensions && zoomRef.current && filteredLinks) {
      const projection = fitProjection(dimensions, filteredLinks);
      const translate = projection.translate();
      const scale = projection.scale();
      setTransform({
        k: scale,
        x: translate[0],
        y: translate[1],
      });
      select(zoomRef.current)
        .property(
          '__zoom',
          zoomIdentity.translate(translate[0], translate[1]).scale(scale)
        )
        .call(
          // @ts-ignore
          zoomObj
            .scaleExtent([Math.min(scale / 1.2, 100), scale * 300])
            .translateExtent([
              [-translate[0] / scale, -translate[1] / scale],
              [translate[0] / scale, translate[1] / scale],
            ])
            .on('zoom', handleZoomed)
        );
    }
  }
  const zoomObj = useMemo(() => zoom(), []);

  useEffect(() => {
    updateZoomToFit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions, zoomRef.current]);

  const handleFit = (event: any) => {
    if (zoomRef.current) {
      updateZoomToFit();
      // projection.fitExtent(
      //   viewport,
      //   {
      //     type: 'GeometryCollection',
      //     geometries:
      //       facilities
      //       .map(l => ({
      //         type: 'Point' as 'Point',
      //         coordinates: l
      //       }))
      //   }
      // );

      // @ts-ignore
      // zoomObj.scaleBy(select(zoomRef.current),1.2);
    }
  };

  const handleZoomIn = (event: any) => {
    if (zoomRef.current) {
      // @ts-ignore
      zoomObj.scaleBy(select(zoomRef.current), 1.2);
    }
  };

  const handleZoomOut = (event: any) => {
    if (zoomRef.current) {
      // @ts-ignore
      zoomObj.scaleBy(select(zoomRef.current), 1 / 1.2);
    }
  };

  const handleZoomed = (event: any) => {
    if (transform) {
      const {k, x, y} = event.transform;
      // if (k !== transform.k) {
      //   setZooming(true);
      // }
      setFocusPos(undefined);
      setTransform({k, x, y});
    }
  };

  // const handleZoomEnded = (event: any) => {
  //   setZooming(false);
  // }

  useEffect(() => {
    if (zoomRef.current) {
      zoomRef.current.style.cursor = hoverItem ? 'pointer' : 'default';
    }
  }, [hoverItem, zoomRef.current]);
  const handleClick = (evt: React.MouseEvent) => {
    if (hoverItem) {
      setSelectedFacility(hoverItem);
      setSelectedNeighbors(neighbors);
    } else {
      setSelectedFacility(undefined);
    }
  };

  const handleMouseMove = (evt: React.MouseEvent) => {
    if (zoomRef.current && spatialIndex && filteredFacilities) {
      const {left, top} = zoomRef.current.getBoundingClientRect();
      const pos: [number, number] = [evt.clientX - left, evt.clientY - top];
      const found = spatialIndex.neighbors(
        pos[0],
        pos[1],
        100,
        NEIGHBOURS_MAX_DISTANCE
      );
      setFocusPos(pos);
      if (found.length > 0) {
        setHoverItem(filteredFacilities[found[0]]);
        setNeighbors(
          found
            .map((idx) => filteredFacilities[idx])
            // TODO: why is this necessary?  filteredFacilities should already be filtered
            .filter(
              (f) =>
                !selectedCompanyFacilitiesSet ||
                selectedCompanyFacilitiesSet.has(f)
            )
        );
      } else {
        if (hoverItem !== undefined) {
          setHoverItem(undefined);
          // setNeighbors(undefined);
        }
      }
    }
  };

  const path = useMemo(() => geoPath().projection(projection), [projection]);

  let content = null;
  if (dimensions && filteredLinks && stepsByFacility) {
    const {width, height} = dimensions;
    const graticule = geoGraticule10();
    const handleMouseLeave = () => {
      setFocusPos(undefined);
      setHoverItem(undefined);
      setHoverLink(undefined);
    };
    content = (
      <>
        <Svg width={width} height={height}>
          <g>
            {/*<rect*/}
            {/*  fill={"rgba(255,255,255,1)"}*/}
            {/*  width={width}*/}
            {/*  height={height}*/}
            {/*/>*/}
            {transform && (
              <>
                <path
                  d={path(graticule)!}
                  stroke={GRATICULE_STROKE}
                  strokeWidth={0.4}
                  fill={BG_COLOR}
                />
                <g>
                  {world.features.map((d: any, i: number) => {
                    return (
                      <path
                        key={i}
                        d={path(d)!}
                        fill={COUNTRY_FILL}
                        stroke={COUNTRY_STROKE}
                        strokeWidth={0.1}
                      />
                    );
                  })}
                </g>
                <LabelsOverlay
                  labels={countryCentroids}
                  projection={projection}
                />
              </>
            )}
          </g>
        </Svg>
        <Svg
          width={width}
          height={height}
          ref={zoomRef}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        >
          <Links
            filteredLinks={filteredLinks}
            path={path}
            setHoverLink={setHoverLink}
          />
          {hoverLink && !hoverItem && (
            <HoverLinkPath d={path(hoverLink.geo)!} />
          )}
          <g
          // style={
          // hoverItem
          //   ? {
          //   // filter: 'grayscale(1)',
          //     opacity: 0.5
          //   }
          //   : undefined
          // }
          >
            <Circles
              filteredFacilities={filteredFacilities}
              projection={projection}
              stepsByFacility={stepsByFacility}
            />
          </g>
          {focusPos && hoverItem && (
            <circle
              cx={focusPos[0]}
              cy={focusPos[1]}
              fill="none"
              stroke={BASE_COLOR}
              strokeWidth={0.2}
              r={NEIGHBOURS_MAX_DISTANCE}
            />
          )}
          {selectedCompanyFacilities && (
            <g>
              {selectedCompanyFacilities.map((f) => (
                <FacilityCircle
                  isSelected={true}
                  key={f.id}
                  steps={stepsByFacility.get(f.id)}
                  facility={f}
                  projection={projection}
                  circleSize={14}
                />
              ))}
            </g>
          )}
          {hoverCompanyNodeFacilities && (
            <g>
              {hoverCompanyNodeFacilities.map((f) => (
                <FacilityCircle
                  isSelected={selectedCompanyFacilitiesSet?.has(f)}
                  isHovered={true}
                  key={f.id}
                  steps={stepsByFacility.get(f.id)}
                  facility={f}
                  projection={projection}
                  circleSize={22}
                />
              ))}
            </g>
          )}
          {selectedFacility && (
            <FacilityCircle
              isSelected={true}
              steps={stepsByFacility.get(selectedFacility.id)}
              facility={selectedFacility}
              projection={projection}
              circleSize={18}
            />
          )}
          {hoverItem && (
            <FacilityCircle
              isSelected={
                selectedFacility === hoverItem ||
                (selectedCompanyFacilitiesSet !== undefined &&
                  selectedCompanyFacilitiesSet.has(hoverItem))
              }
              steps={stepsByFacility.get(hoverItem.id)}
              facility={hoverItem}
              projection={projection}
              circleSize={22}
            />
          )}
        </Svg>
        {hoverItem && selectedFacility !== hoverItem ? (
          <FacilityDetails
            steps={stepsByFacility.get(hoverItem.id)}
            isSelected={false}
            facility={hoverItem}
            neighbors={neighbors}
          />
        ) : selectedFacility ? (
          <FacilityDetails
            steps={stepsByFacility.get(selectedFacility.id)}
            isSelected={true}
            facility={selectedFacility}
            neighbors={selectedNeighbors}
          />
        ) : null}
        {hoverLink && !hoverItem && <LinkDetails link={hoverLink} />}
      </>
    );
  }

  return (
    <Outer ref={ref}>
      {content}
      <ControlsArea>
        <Button onClick={handleZoomIn} title="Zoom in">
          +
        </Button>
        <Button onClick={handleZoomOut} title="Zoom out">
          –
        </Button>
        <Button onClick={handleFit} title="Fit">
          <FitIcon size={16} />
        </Button>
      </ControlsArea>
    </Outer>
  );
};

export default FacilityMap;
