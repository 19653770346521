import React, {Suspense} from 'react';
import {createBrowserHistory} from 'history';
import {Route, Router, Switch} from 'react-router-dom';
import styled from '@emotion/styled';
import LoadingSpinner from './LoadingSpinner';
import Explore from './Explore';
import Home from './Home';
import './App.css';
import headerBgImg from './images/header-background.svg';

const history = createBrowserHistory();

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 100vh;
  background-image: url('${headerBgImg}');
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  //& > *+* {
  //  margin-top: 20px;
  //}
`;

function App() {
  return (
    <Container>
      <Router history={history}>
        <Suspense fallback={<LoadingSpinner/>}>
          <Switch>
            <Route path="/explore" component={Explore}/>
            <Route path="/" component={Home}/>
          </Switch>
        </Suspense>
      </Router>
    </Container>

  );
}

export default App;
