import styled from '@emotion/styled';
import React, {useMemo} from 'react';
import Select, {components as selectComponents} from 'react-select';
import {
  BASE_COLOR,
  BASE_LIGHTER_COLOR1,
  BASE_LIGHTER_COLOR2,
} from './constants';

interface Props<T> {
  title: string;
  options?: T[];
  getLabel: (t: T) => string;
  selected?: T | undefined;
  isSearchable: boolean;
  onSelect?: (t: T | undefined) => void;
}

const Outer = styled.div`
  flex-grow: 1;
  font-weight: bold;
  font-size: 0.7rem;
  min-width: 150px;
  max-width: 220px;
  div {
    color: ${BASE_COLOR};
  }
`;

const OptionOuter = styled.div`
  font-size: 0.7rem;
`;

const SelectOption: React.FC<any> = (props) => {
  return (
    <OptionOuter>
      <selectComponents.Option {...props} />
    </OptionOuter>
  );
};

function Filter<T>({
  title,
  options,
  getLabel,
  selected,
  isSearchable,
  onSelect,
}: Props<T>) {
  const _options = useMemo(
    () =>
      options?.map((d) => ({
        label: getLabel(d),
        value: d,
        datum: d,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );
  const _selected = useMemo(
    () => _options?.find((opt) => opt.value === selected),
    [_options, selected]
  );
  return (
    <Outer>
      <Select
        components={{Option: SelectOption}}
        isOptionDisabled={() => false}
        onChange={(d) => onSelect && onSelect(d ? d.datum : undefined)}
        value={_selected}
        options={_options || []}
        isSearchable={isSearchable}
        isClearable={true}
        isFixed={true}
        menuPortalTarget={document.body}
        escapeClearsValue={true}
        placeholder={title}
        // filterOption={(option, input) => matchesSearchQuery(input, option.label)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: BASE_COLOR,
            primary50: BASE_LIGHTER_COLOR1,
            primary25: BASE_LIGHTER_COLOR2,
          },
        })}
        styles={{
          option: (base, props) => ({
            ...base,
            cursor: 'pointer',
          }),
          control: (base, props) => ({
            ...base,
            cursor: 'pointer',
          }),
          indicatorsContainer: (base, props) => ({
            ...base,
            cursor: 'pointer',
          }),
        }}
      />
    </Outer>
  );
}

export default Filter;
