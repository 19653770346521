import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AboutModal from './AboutModal';
import Button from './Button';
import ContactModal from './ContactModal';
import DisclaimerModal from './DisclaimerModal';
import ExpandButton from './ExpandButton';
import FacilityMap from './FacilityMap';
import Filter from './Filter';
import LoadingSpinner from './LoadingSpinner';
import { getDataIfLoaded, useStore } from './Store';
import SupplyChains from './SupplyChains';
import SwitchControl from './SwitchControl';
import { BG_COLOR } from './constants';
import { Cell, GridArea } from './grid';
import { FetchStatus } from './types';
import useDimensions from './useDimensions';
import useKeypress from './useKeypress';

const BREAKPOINT_WIDTH = 800;

const OuterContainer = styled.div`
  padding: 0.5rem 0.5rem;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Outer = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  box-shadow: 0 0 1px #8f9d35;
  background: ${BG_COLOR};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

const TitleArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  & > * + * {
    margin-left: 1em;
  }
`;

const Title = styled.h1`
  font-family: Roboto, sans-serif;
  letter-spacing: 1px;
  margin: 0;
  //flex-grow: 1;
  font-size: 1.5rem;
  text-transform: uppercase;
  white-space: nowrap;
  & > sup {
    font-size: 0.8rem;
    position: relative;
    top: -5px;
    left: 4px;
  }
`;

const HeaderButton = styled(Button)`
  cursor: pointer;
  font-size: 0.7rem;
`;

const FiltersGridArea = styled(GridArea)`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (min-width: ${BREAKPOINT_WIDTH}px) {
    //align-self: flex-end;
    justify-content: flex-end;
  }
  & > * + * {
    margin-left: 20px;
  }
`;

const SwitchArea = styled.div`
  display: flex;
  @media (min-width: ${BREAKPOINT_WIDTH}px) {
    flex-direction: row;
    & > * + * {
      margin-left: 20px;
    }
  }
  @media (max-width: ${BREAKPOINT_WIDTH}px) {
    flex-direction: column;
    & > * + * {
      margin-top: 10px;
    }
    //margin-top: 20px;
  }
`;

// const SwitchControlsArea = styled.div`
//   display: flex;
//   flex-direction: row;
//   &> *+* {margin-left: 5px; }
//   @media(min-width: ${BREAKPOINT_WIDTH}px) {
//     padding: 10px 5px 20px 0px;
//   }
//   @media(max-width: ${BREAKPOINT_WIDTH}px) {
//     margin-top: 7px;
//   }
// `;

const Content = styled.div<{showMap: boolean; showChains: boolean}>(
  ({showMap, showChains}) => `
  display: grid;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 20px;
  //justify-content: stretch;

  @media(min-width: ${BREAKPOINT_WIDTH}px) {
    grid-template-columns: auto auto;
    row-gap: 10px;
    ${
      showMap && showChains
        ? `
    grid-template-rows: 40px repeat(2, minmax(250px, calc(50% - 25px)));
    grid-template-areas:
    "title filters"
    "chains chains"
    "map map";
    `
        : showMap
        ? `
    grid-template-rows: 40px minmax(250px, calc(100% - 50px));
    grid-template-areas:
    "title filters"
    "map map";
    `
        : `
    grid-template-rows: 40px minmax(250px, calc(100% - 50px));
    grid-template-areas:
    "title filters"
    "chains chains";
    `
    }
  }
    
  @media(max-width: ${BREAKPOINT_WIDTH}px) {
    grid-template-columns: auto;
    row-gap: 10px;
    ${
      showMap && showChains
        ? `
    grid-template-rows: 25px 40px repeat(2, minmax(250px, calc(50% - 45px)));
    grid-template-areas:
    "title"
    "filters"
    "chains"
    "map";
    `
        : showMap
        ? `
    grid-template-rows: 25px 40px minmax(250px, calc(100% - 65px));
    grid-template-areas:
    "title"
    "filters"
    "map";
    `
        : `
    grid-template-rows: 25px 40px minmax(250px, calc(100% - 65px));
    grid-template-areas:
    "title"
    "filters"
    "chains";
    `
    }
  }
`
);

export function useFetchData() {
  const fetchData = useStore((state) => state.fetchData);
  const data = useStore((state) => state.data);
  const fetchWorld = useStore((state) => state.fetchWorld);
  const world = useStore((state) => state.world);
  const fetchCountries = useStore((state) => state.fetchCountries);
  const countryCentroids = useStore((state) => state.countryCentroids);
  useEffect(
    () => {
      if (data?.status !== FetchStatus.DONE) fetchData();
      if (world?.status !== FetchStatus.DONE) fetchWorld();
      if (countryCentroids?.status !== FetchStatus.DONE) fetchCountries();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return [data, world];
}

export default function Explore() {
  const [data, world] = useFetchData();
  const companies = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.companies)
  );
  const isSimplified = useStore((state) => state.isSimplified);
  const setSimplified = useStore((state) => state.setSimplified);
  const selectedCompany = useStore((state) => state.selectedCompany);
  const setSelectedCompany = useStore((state) => state.setSelectedCompany);
  useKeypress('Escape', () => setSelectedCompany(undefined));
  const [ref, dimensions] = useDimensions<HTMLDivElement>();
  const isSmallScreen =
    dimensions != null && (dimensions.width < 800 || dimensions.height < 600);
  const [showChains, setShowChains] = useState(true);
  const [showMap, setShowMap] = useState(true);
  const history = useHistory();
  const [showDisclaimer, setShowDisclaimer] = useState(
    history.location.hash === '#disclaimer'
  );
  const [showAbout, setShowAbout] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const handleCloseDisclaimer = () => {
    setShowDisclaimer(false);
    history.replace('/explore');
  };
  return (
    <OuterContainer>
      <Outer ref={ref}>
        {!data ||
        data.status === FetchStatus.LOADING ||
        !world ||
        world.status === FetchStatus.LOADING ? (
          <LoadingSpinner />
        ) : data.status === FetchStatus.ERROR ||
          world.status === FetchStatus.ERROR ? (
          <div>Oops… Couldn't load data. Sorry about that.</div>
        ) : (
          <Content showMap={showMap} showChains={showChains}>
            {showChains && (
              <GridArea name="chains">
                <Cell>
                  <SupplyChains data={data.data} />
                  <ExpandButton
                    isExpanded={!showMap}
                    onClick={() => setShowMap(!showMap)}
                  />
                </Cell>
              </GridArea>
            )}
            {showMap && (
              <GridArea name="map">
                <Cell>
                  <FacilityMap />
                  <ExpandButton
                    isExpanded={!showChains}
                    onClick={() => setShowChains(!showChains)}
                  />
                </Cell>
              </GridArea>
            )}
            <GridArea name="title">
              <TitleArea>
                <Title>
                  <Link to="/">Cobalt Supply Chain</Link>
                  <sup>BETA</sup>
                </Title>
                {!isSmallScreen && (
                  <>
                    <HeaderButton onClick={() => setShowDisclaimer(true)}>
                      Disclaimer
                    </HeaderButton>
                    <HeaderButton onClick={() => window.open('https://drive.google.com/file/d/1RLkxQ5z-FJndw41uKB-Qfj06WnaG4sf8/view')}>
                      User guide
                    </HeaderButton>
                    <HeaderButton onClick={() => setShowAbout(true)}>
                      About
                    </HeaderButton>
                    <HeaderButton onClick={() => setShowContact(true)}>
                      Contact
                    </HeaderButton>
                  </>
                )}
              </TitleArea>
            </GridArea>
            <FiltersGridArea name="filters">
              {/*<FilterIcon width={17} height={15} color="#355464"/>*/}
              <SwitchArea>
                <SwitchControl
                  title={'Simplified mode'}
                  checked={isSimplified}
                  onChange={setSimplified}
                />
                {/*<SwitchControl*/}
                {/*  title={"Supply chain"}*/}
                {/*  checked={showChains}*/}
                {/*  onChange={handleShowChains}*/}
                {/*/>*/}
                {/*<SwitchControl*/}
                {/*  title={"Facility map"}*/}
                {/*  checked={showMap}*/}
                {/*  onChange={handleShowMap}*/}
                {/*/>*/}
              </SwitchArea>
              <Filter
                title="Search&nbsp;company…"
                isSearchable={!isSmallScreen}
                options={companies}
                selected={selectedCompany}
                getLabel={(c) => c.name}
                onSelect={setSelectedCompany}
              />
            </FiltersGridArea>
          </Content>
        )}
        {showDisclaimer && <DisclaimerModal onClose={handleCloseDisclaimer} />}
        {showAbout && <AboutModal onClose={() => setShowAbout(false)} />}
        {showContact && <ContactModal onClose={() => setShowContact(false)} />}
      </Outer>
    </OuterContainer>
  );
}
