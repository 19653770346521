import styled from '@emotion/styled';
import {FC} from 'react';
import {FaExternalLinkAlt} from 'react-icons/fa';
import {getDataIfLoaded, useStore} from './Store';
import {BASE_LIGHTER_COLOR1} from './constants';
import {Company} from './types';
type Props = {
  company: Company;
};

const Outer = styled.div`
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledHeader = styled.h3`
  padding-top: 1em;
`;

const StyledDate = styled.div`
  color: ${BASE_LIGHTER_COLOR1};
  font-size: 0.8em;
  margin-top: 5px;
`;

const MAX_NEWS_ITEMS = 3;

const BhrrcCompanyNews: FC<Props> = (props) => {
  const {company} = props;
  const newsItems = useStore((state) =>
    getDataIfLoaded(state.data, (data) => data.prepared.bhrrc)
      ?.newsItemsByCompany.get(company.name)
      ?.slice(0, MAX_NEWS_ITEMS)
  );

  return newsItems?.length ? (
    <Outer>
      <StyledHeader>Latest News</StyledHeader>

      {newsItems.map((newsItem, i) => (
        <StyledItem key={i} className="bhr">
          <a
            key={i}
            href={
              newsItem.url
                ? newsItem.url
                : `https://www.google.com/search?q=${newsItem.title}`
            }
            target="_blank"
            rel="noreferrer"
          >
            {newsItem.title}
            <FaExternalLinkAlt
              style={{
                marginLeft: 5,
                width: 10,
                height: 10,
                alignSelf: 'start',
              }}
            />
            <StyledDate>{newsItem.date}</StyledDate>
          </a>
        </StyledItem>
      ))}
    </Outer>
  ) : null;
};

export default BhrrcCompanyNews;
